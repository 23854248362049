import photo1 from "./Images/1.jpg";
import photo2 from "./Images/2.jpg";
import photo3 from "./Images/3.jpg";
import photo5 from "./Images/5.jpg";
import photo6 from "./Images/6.jpg";
import photo7 from "./Images/7.jpg";
import photo8 from "./Images/8.jpg";
import photo9 from "./Images/9.jpg";
import photo10 from "./Images/10.jpg";
import photo11 from "./Images/11.jpg";
import photo12 from "./Images/12.jpg";
import photo13 from "./Images/13.jpg";
import photo14 from "./Images/14.jpg";
import photo15 from "./Images/15.jpg";
import photo16 from "./Images/16.jpg";
import photo17 from "./Images/17.jpg";
import photo18 from "./Images/18.jpg";
import photo19 from "./Images/19.jpg";
import photo20 from "./Images/20.jpg";
import photo21 from "./Images/21.jpg";
import photo22 from "./Images/22.jpg";
import photo23 from "./Images/23.jpg";
import photo24 from "./Images/24.jpg";
import photo25 from "./Images/25.jpg";
import photo26 from "./Images/26.jpg";
import photo27 from "./Images/27.jpg";
import photo28 from "./Images/28.jpg";
import photo29 from "./Images/29.jpg";
import photo30 from "./Images/30.jpg";
import photo31 from "./Images/31.jpg";
import photo32 from "./Images/32.jpg";
import photo33 from "./Images/33.jpg";
import photo34 from "./Images/34.jpg";
import photo35 from "./Images/35.jpg";
import photo36 from "./Images/36.jpg";
import photo37 from "./Images/37.jpg";
import photo38 from "./Images/38.jpg";
import photo39 from "./Images/39.jpg";
import photo40 from "./Images/40.jpg";
import photo41 from "./Images/41.jpg";
import photo42 from "./Images/42.jpg";
import photo43 from "./Images/43.jpg";
import photo44 from "./Images/44.jpg";
import photo45 from "./Images/45.jpg";
import photo46 from "./Images/46.jpg";
import photo47 from "./Images/47.jpg";
import photo48 from "./Images/48.jpg";
import photo49 from "./Images/49.jpg";
import photo50 from "./Images/50.jpg";
import photo51 from "./Images/51.jpg";
import photo52 from "./Images/52.jpg";
import photo53 from "./Images/53.jpg";
import photo54 from "./Images/54.jpg";
import photo55 from "./Images/55.jpg";
import photo56 from "./Images/56.jpg";
import photo57 from "./Images/57.jpg";
import photo58 from "./Images/58.jpg";
import photo59 from "./Images/59.jpg";
import photo60 from "./Images/60.jpg";
import photo61 from "./Images/61.jpg";
import photo62 from "./Images/62.jpg";
import photo63 from "./Images/63.jpg";
import photo64 from "./Images/64.jpg";
import photo65 from "./Images/65.jpg";
import photo66 from "./Images/66.jpg";
import photo67 from "./Images/67.jpg";
import photo68 from "./Images/68.jpg";
import photo69 from "./Images/69.jpg";
import photo70 from "./Images/70.jpg";
import Hom1 from "./Images/Slider/Hom/1.jpg";
import Hom2 from "./Images/Slider/Hom/2.jpg";
import Hom3 from "./Images/Slider/Hom/3.jpg";
import Hom4 from "./Images/Slider/Hom/4.jpg";
import Hom5 from "./Images/Slider/Hom/5.jpg";
import Hom6 from "./Images/Slider/Hom/6.jpg";
import Hom8 from "./Images/Slider/Hom/8.jpg";
import Hom9 from "./Images/Slider/Hom/9.jpg";
import Hom10 from "./Images/Slider/Hom/10.jpg";
import bandurchenko1 from "./Images/Slider/bandurchenko/1.jpg";
import bandurchenko2 from "./Images/Slider/bandurchenko/2.jpg";
import bandurchenko3 from "./Images/Slider/bandurchenko/3.jpg";
import bandurchenko4 from "./Images/Slider/bandurchenko/4.jpg";
import bandurchenko5 from "./Images/Slider/bandurchenko/5.jpg";
import bandurchenko6 from "./Images/Slider/bandurchenko/6.jpg";
import bandurchenko7 from "./Images/Slider/bandurchenko/7.jpg";
import bandurchenko8 from "./Images/Slider/bandurchenko/8.jpg";
import bandurchenko9 from "./Images/Slider/bandurchenko/9.jpg";
import bandurchenko10 from "./Images/Slider/bandurchenko/10.jpg";
import verhovodov1 from "./Images/Slider/verhovodov/1.jpg";
import verhovodov2 from "./Images/Slider/verhovodov/2.jpg";
import verhovodov3 from "./Images/Slider/verhovodov/3.jpg";
import sribn1 from "./Images/Slider/Sribn/1.jpg";
import sribn2 from "./Images/Slider/Sribn/2.jpg";
import marcen1 from "./Images/Slider/Marcen/1.jpg";
import marcen2 from "./Images/Slider/Marcen/2.jpg";
import menshikov1 from "./Images/Slider/menshikov/1.jpg";
import menshikov2 from "./Images/Slider/menshikov/2.jpg";
import nosach1 from "./Images/Slider/nosach/1.jpg";
import nosach2 from "./Images/Slider/nosach/2.jpg";
import ostrouh1 from "./Images/Slider/ostrouh/1.jpg";
import ostrouh2 from "./Images/Slider/ostrouh/2.jpg";
import ostrouh3 from "./Images/Slider/ostrouh/3.jpg";
import skala1 from "./Images/Slider/skala/1.jpg";
import skala2 from "./Images/Slider/skala/2.jpg";
import skala3 from "./Images/Slider/skala/3.jpg";
import skala4 from "./Images/Slider/skala/4.jpg";
import skala5 from "./Images/Slider/skala/5.jpg";
import skala6 from "./Images/Slider/skala/6.jpg";
import coy1 from "./Images/Slider/coy/1.jpg";
import coy2 from "./Images/Slider/coy/2.jpg";
import coy3 from "./Images/Slider/coy/3.jpg";
import chernenko1 from "./Images/Slider/chernenko/1.jpg";
import chernenko3 from "./Images/Slider/chernenko/3.jpg";
import chernenko4 from "./Images/Slider/chernenko/4.jpg";
import uhim1 from "./Images/Slider/uhim/1.jpg";
import uhim2 from "./Images/Slider/uhim/2.jpg";
import uhim3 from "./Images/Slider/uhim/3.jpg";
import uhim4 from "./Images/Slider/uhim/4.jpg";
import uhim5 from "./Images/Slider/uhim/5.jpg";
import uhim6 from "./Images/Slider/uhim/6.jpg";
import romanov1 from "./Images/Slider/romanov/1.jpg";
import romanov2 from "./Images/Slider/romanov/2.jpg";
import romanov3 from "./Images/Slider/romanov/3.jpg";
import romanov5 from "./Images/Slider/romanov/5.jpg";
import romanov7 from "./Images/Slider/romanov/7.jpg";
import romanov8 from "./Images/Slider/romanov/8.jpg";
import komar1 from "./Images/Slider/komar/1.jpg";
import komar2 from "./Images/Slider/komar/2.jpg";
import komar3 from "./Images/Slider/komar/3.jpg";
import komar4 from "./Images/Slider/komar/4.jpg";
import komar5 from "./Images/Slider/komar/5.jpg";
import babakin1 from "./Images/Slider/babakin/1.jpg";
import babakin2 from "./Images/Slider/babakin/2.jpg";
import plug1 from "./Images/Slider/plug/1.jpg"
import plug2 from "./Images/Slider/plug/2.jpg"
import plug3 from "./Images/Slider/plug/3.jpg"
export const Hero = [
  {
    id: 49,
    name: "Микола Вікторович БАБАКІН",
    date: "27.10.1970 - 28.11.2023",
    photo: photo49,
    position: "старший солдат",
    texts: [
      "Вільногірець Микола Вікторович БАБАКІН навічно увійде в історію нашої громади, всієї України як Герой-визволитель, як вірний син Українського народу.",
      "Його світле і чесне життя забрав клятий ворог.",
      "Народився Микола 27 жовтня 1970 року у селищі Таромське Дніпропетровської області.",
      "Навчався у місцевій середній школі №105, потім в СПТУ-15 від заводу ім. Петровського в обласному центрі.",
      "Набув професію газоелектрозварника.",
      "На зазначеному підприємстві розпочалась його трудова діяльність.",
      "Як і більшість юнаків того часу, пройшов армійську підготовку.",
      "Служив у внутрішніх військах у м. Золочів Львівської області.",
      "З грудня 1993 року разом із родиною проживав у Вільногірську.",
      "Працював на Вільногірському ГМК в ремонтно-механічному цеху, цеху водопостачання, на збагачувальному виробництві. Певний час їздив на заробітки за кордон.",
      "Всі, хто знав Миколу Вікторовича, запам'ятають його як компанійську людину з гарним почуттям гумору, завжди сповненого оптимізму і доброти.",
      "Коли розпочалася широкомасштабна російська агресія, вже 02 березня 2022 року Микола БАБАКІН пішов на фронт по мобілізації.",
      "Його вроджене почуття справедливості і гідності відіграли не останню роль.",
      "Вдома він так і сказав: -По-іншому не можу, не пробачив би собі, якби залишився тут.",
      "Наш земляк пройшов нелегкий шлях. Він бив ворога у Красній Поляні, Волновасі, Вугледарі, під Бахмутом.",
      "Навіть у короткострокові відпустки, які вдавалося отримати від командирів для поновлення сил, у період реабілітації після операції він волонтерив: замовляв для побратимів буржуйки на ГМК, збирав все необхідне для фронту у Вільногірську, Дніпрі, Запоріжжі.",
      "На запитання, як там, на фронті, завжди заспокоював: -Все буде добре. Не переживайте!",
      "Останнім часом брав участь у боях поблизу Авдіївки Донецької області.",
      "Служив у Збройних Силах України старшим солдатом у складі мотопіхотного батальйону славетної 53-ої окремої механізованої бригади імені князя Володимира Мономаха.",
      "28 листопада 2023 року став останнім днем життя Миколи Вікторовича БАБАКІНА.",
      "Він до останньої хвилини залишився вірним військовій присязі, Українському народові, собі.",
      "Щирі співчуття від усієї громади дружині і доньці воїна, всім його рідним, друзям, колегам, побратимам.",
      "Його ім'я назавжди в наших серцях! Дякуємо за подвиг ціною в життя.",
      "Вічна йому пам'ять і світлий спомин.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
      "07 квітня 2024 року старший солдат Микола Вікторович БАБАКІН нагороджений почесною відзнакою командира військової частини А0536 `За оборону Авдіївки` (посмертно).",
    ],
    sliderImg: [babakin1, babakin2],
  },
  {
    id: 59,
    name: "Віталій Володимирович БАБИЧ",
    date: "23.07.1977 - 09.08.2023",
    photo: photo59,
    position: "солдат",
    texts: [
      "Щодня клята війна розсилає скорботні звістки в родини українців. Ворог вбиває наших громадян, цвіт нації.",
      "Сповіщення-підтвердження про загибель отримала ще одна родина з нашої громади: 09 серпня 2023 року на вогневій позиції під Старомайорським Донецької області поліг смертю хоробрих Віталій Володимирович БАБИЧ, житель села Доброгірське.",
      "Народився Віталій 23 липня 1977 року у селі Урожайне Бериславського району Херсонської області. Потім родина переїхала в наші краї. Віталій навчався у Вільногірській середній школі №1. Спеціальну технічну освіту отримав в одному із технікумів м. Дніпродзержинськ (зараз – Кам’янське). Як більшість юнаків, пройшов строкову службу в армії. Служив на Івано-Франківщині.",
      "Трудовий шлях Віталія Володимировича пов’язаний зі службою в міліції, з роботою у ТОВ «Вільногірське скло», на філії «Вільногірський ГМК» АТ «ОГХК». На комбінаті він працював на ГТВ слюсарем.",
      "Завжди відповідальний, безвідмовний, добрий за характером, гарний син, батько, чоловік – таким його назавжди запам’ятають рідні, друзі, колеги по роботі.",
      "Коли розпочалась широкомасштабна агресія росії проти України, Віталій Володимирович вже 16 березня 2022 року був призваний на військову службу по мобілізації першим відділом у місті Верхньодніпровську Кам'янського РТЦК та СП Дніпропетровської області.",
      "Захищав Запорізьку область, брав участь у боях в районі Гуляйполя. Воював у Донецькій області на Волноваському напрямку.",
      "09 серпня минулого року загинув в зоні ведення бойових дій внаслідок влучання КАБ.",
      "Деякий час вважався зниклим безвісти. Але після проведення військовими пошукової роботи та ДНК-експертизи підтвердився факт загибелі нашого земляка.",
      "До останньої хвилини життя солдат Віталій Володимирович БАБИЧ залишився вірним військовому і громадянському обов’язку, Україні, своєму народові.",
      "Низький уклін його батькам - Лідії Яківні та Володимирові Гавриловичу, глибокі співчуття синові Віталію, дружині Надії.",
      "Ім’я Героя навіки в наших серцях. Громада висловлює шану і подяку воїну за безсмертний подвиг в ім’я миру і свободи.",
      "Поховали Віталія Володимировича у селі Доброгірське Вільногірської міської територіальної громади на місцевому кладовищі.",
    ],
  },
  {
    id: 2,
    name: "Владислав Сергійович БАДЕНКО",
    date: "31.05.1999 - 30.03.2023",
    photo: photo2,
    position: "помічник гранатометника",
    texts: [
      "Злочини проти людяності і проти людства з боку рашистів не виправдає жоден суд, ні земний, ні небесний. Під Кремінною Луганської області внаслідок ворожого артобстрілу загинув наш земляк Владислав Сергійович БАДЕНКО. Було йому всього лише неповних 24 роки.",
      "Народився Владислав 31 травня 1999 року в місті Миколаїв. Жив і навчався в загальноосвітній школі в селі Вишневе на той час П’ятихатського району. Після школи продовжив навчання у Західно-Дніпровському центрі професійно-технічної освіти. Отримав диплом кваліфікованого робітника з професії «Машиніст екскаватора. Електрослюсар (слюсар черговий та ремонтного устаткування). Електрогазозварник». ",
      "Владислав був завжди дуже відповідальним учнем. Вихований, розсудливий, діловий, він не лише добре вчився, а й брав активну участь у культурному та спортивному житті навчального закладу. Паралельно з навчанням працював у ТОВ «Скляний Альянс». Таким його запам’ятали педагоги. ",
      "Строкову службу Владислав Баденко пройшов у військах хімічної розвідки.",
      "Після демобілізації знову повернувся у ТОВ «Скляний Альянс», де і працював до початку широкомасштабної російської агресії проти України.",
      "30 серпня 2022 року пішов служити по мобілізації до лав Національної гвардії України. На фронті солдат НГУ Владислав БАДЕНКО на позивний «Малиш» був помічником гранатометника. ",
      "Увечері 30 березня 2023 року Владислав БАДЕНКО повинен був виїхати додому у короткотермінову відпустку після шпиталю, але сталося по-іншому… ",
      "У земляка залишились мама, брат та кохана дівчина. Від імені всієї громади висловлюю щирі глибокі співчуття рідним та близьким Владислава, а також всім, хто знав, працював і товаришував з ним. Це непоправна втрата для всіх нас. ",
      "Світла пам’ять і вічна слава Владиславові Сергійовичу БАДЕНКУ! Він навічно в строю, в рядах всіх наших співвітчизників, які віддали життя за свободу, незалежність і цілісність України! ",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно). ",
      "Згідно з Указом Президента України №500/2023 від 23.08.2023 за особисту мужність і самовідданість, виявлені у захисті державного суверенітету та територіальної цілісності України, сумлінне та бездоганне служіння Українському народові солдата Владислава Сергійовича БАДЕНКА нагороджено орденом 'За мужність' ІІІ ступеня.",
    ],
  },  
  {
    id: 3,
    name: "Олег Миколайович БАНДУРЧЕНКО",
    date: "03.12.1959 - 08.10.2022",
    photo: photo3,
    position: "командир мінометної батареї",
    texts: [
      "Наша територіальна громада, наше місто втратили на війні ще одного земляка, справжнього патріота, активного і принципового громадянина, який завжди відстоював інтереси держави, гідність її народу – Олега Миколайовича БАНДУРЧЕНКА.",
      "Олег Миколайович народився 03 грудня 1959 року у м. Кам’янське.Навчався у середній школі № 2.Після школи закінчив Вільногірський технікум, вивчився на електрика.",
      "Працював на Вільногірському ГМК спочатку електриком, потім енергетиком,головним інженером у КП «Жилсервіс», на заводі «ЗБВ». За 5 років в МЖК збудував житло для своєї родини. ",
      "Олег Миколайович, досить обізнаний і компетентний з багатьох питань, за характером прямолінійний, впевнений у собі й наполегливий, завжди користувався заслуженим авторитетом і повагою у громаді. Обирався депутатом Вільногірської міської ради двох скликань. Про таких кажуть - людина слова і діла, вірний син своєї Вітчизни.",
      "Він з молодих років ніколи не залишався байдужим до подій, що відбуваються у суспільстві, до людських доль. Мабуть, саме тому 1989 року добровільно поїхав у вірменське місто Спітак допомагати людям усувати наслідки потужного землетрусу (тоді загинуло щонайменше 25 тисяч осіб, ще близько ста сорока тисяч стали інвалідами, 514 тисяч людей залишилися без даху над головою).",
      "Олегові Бандурченку були близькими ідеї Помаранчевої революції. Саме завдяки йому і його однодумцям 2004 року прапор Вільногірська побував у Києві на Майдані серед тисяч прапорів – символів перемін. ",
      "Він вітав переломні зміни у державі 2013-2014 року. А з 2014 року, коли над країною нависла реальна загроза широкомасштабного вторгнення з боку росії,без вагань пішов виборювати нашу незалежність і територіальну цілісність у зоні АТО та ООС.",
      "Всі ці довгі роки Герой мужньо і звитяжно воював за нашу свободу, за наше мирне життя. Пройшов шлях від простого солдата до штаб-сержанта, командира мінометної батареї однієї з наших військових частин, майже увесь час перебуваючи на офіцерських посадах.",
      "За увесь час служби в рядах ЗСУ Олег Миколайович по праву відзначений державними нагородами: орденами «За мужність і відвагу», «Сталевий Хрест Непереможних», «За мужність» ІІІ ступеня, медаллю «За оборону рідної держави», нагрудними знаками «Знак пошани» і «Гідність та честь», почесним нагрудним знаком начальника Генерального штабу – Головнокомандувача ЗСУ «За взірцевість у військовій службі» ІІІ ступеня, відзнаками «За службу», «Лицарський хрест родини Мазеп», «За службу державі», двома відзнаками командувача об’єднаних сил «Козацький хрест» ІІІ ступеня.",
      "Лютий 2022 року застав Олега Бандурченка на Донеччині, потім були військові дії в Луганській області, далі їхню частину перекинули на херсонський напрямок.",
      "08 жовтня 2022 року під час виконання бойових завдань в районі населеного пункту Давидів Брід Бериславського району Херсонської області Олег Миколайович Бандурченко загинув від отриманих травм, несумісних із життям.",
      "Вірний військовому і громадянському обов’язку, Олег Миколайович Бандурченко залишиться в пам’яті народній назавжди. ",
      "Висловлюємо щирі співчуття матері, сестрі, дружині, дітям, онукам Героя, всім його колегам, друзям, бойовим побратимам. Це непоправне горе і болісна для всіх втрата. ",
      "Вічна слава Герою! Слава вірному синові України! ",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [
      bandurchenko1,
      bandurchenko2,
      bandurchenko3,
      bandurchenko4,
      bandurchenko5,
      bandurchenko6,
      bandurchenko7,
      bandurchenko8,
      bandurchenko9,
      bandurchenko10,
    ],
  },
  {
    id: 63,
    name: "Олександр Борисович БАРАТЕЙ",
    date: "26.07.1997 - 19.08.2024",
    photo: photo63,
    position: "солдат",
    texts: [
      "За незалежність і свободу нашої держави, за мирне небо над нашими головами на всіх фронтах і в тилу ризикують життям тисячі мужніх синів і доньок України. На жаль, реалії війни жорстокі: не всі повертаються додому. І ці втрати завжди відгукуватимуться болем у наших серцях.",
      "У Вільногірську міську територіальну громаду надійшла звістка про загибель у бою ще одного земляка – Олександра Борисовича БАРАТЕЯ. Це сталося 19 серпня 2024 року в ході курської операції ЗСУ.",
      "Олександр БАРАТЕЙ народився 26 липня 1997 року у селі Долинське П’ятихатського району Дніпропетровської області. Зараз це село входить у Саксаганську ОТГ Кам’янського району.",
      "Після навчання у місцевій школі закінчив Західно-Дніпровський центр професійно-технічної освіти. Працював у ТОВ «Склянний Альянс» у Вільногірську налагоджувальником машин контролю, на філії 'ВГМК' АТ 'ОГХК' електриком, потім знову повернувся на скляне виробництво. Чуйна, доброзичлива людина, гарний спеціаліст – саме таким залишиться наш земляк у спогадах колег і друзів.",
      "У Вільногірську Олександр створив родину. Разом із дружиною Світланою з любов’ю і турботою виховували доньку Варвару.",
      "Широкомасштабне вторгнення росії не залишило осторонь цю сім’ю. 08 травня 2024 року третім відділом у місті П'ятихатки Кам'янського РТЦК та СП Олександра призвано на військову службу по мобілізації.",
      "Після проходження військової підготовки служив у 82-й окремій десантно-штурмовій бригаді.",
      "Солдат Збройних Сил України Олександр Борисович БАРАТЕЙ із позивним «Каспер» загинув на полі бою в районі населеного пункту Шептухівка Кореневського району Курської області російської федерації, мужньо виконавши свій військовий і громадянський обов’язок у боротьбі за Україну, за її державність, за мирне життя Українського народу.",
      "Вся Вільногірська громада висловлює глибокі співчуття всім рідним і близьким нашого захисника, його друзям, колегам по роботі, бойовим побратимам.",
      "Поховали воїна на Алеї Слави міського кладовища.",
    ],
  },
  {
    id: 64,
    name: "Руслан Юрійович БІДА",
    date: "26.10.1988 - 25.08.2024",
    photo: photo64,
    position: "солдат",
    texts: [
      "Вільногірська громада знову у жалобі. Життя ще одного захисника України обірвала війна.",
      "Руслан Юрійович БІДА ніколи не бував у Вільногірську. Він народився 26 жовтня 1988 року у місті Попасна Сєвєродонецького району Луганської області.",
      "Закінчив там загальноосвітню школу №25, професійно-технічне училище №85. Здобув професію різника по металу. За фахом працював на місцевому вагонно-ремонтному заводі.",
      "У результаті військових дій, які розгорталися в тих краях, виїхав працювати в місто Балаклея Харківської області. А батьки воїна, Людмила Олександрівна та Юрій Федорович, теж вимушені були рятуватись, залишити рідне місто. Вони виїхали з міста Попасна у березні 2022 року і мешкають у Вільногірську як внутрішньо переміщені особи.",
      "18 травня 2024 року Ізюмським РТЦК та СП Харківської області їхнього сина було призвано на військову службу по мобілізації.",
      "Руслан Юрійович пройшов військову підготовку в Чернігівській та Сумській областях. Далі розпочалась військова служба.",
      "Під час виконання бойового завдання в зоні бойових дій поблизу населеного пункту Руская Конопелька Курської області рф отримав важке поранення.",
      "На жаль, врятувати життя солдатові медики не змогли. Він помер у Національному військово-медичному клінічному центрі «Головний військовий клінічний госпіталь» у м. Київ 25 серпня 2024 року.",
      "Вся Вільногірська громада висловлює глибокі співчуття батькам і сестрі Руслана БІДИ, його бойовим побратимам.",
      "Низькій уклін Героєві за подвиг в ім’я Українського народу, в ім’я незалежності і територіальної цілісності нашої батьківщини.",
      "Батьки воїна прийняли рішення поховати сина на Алеї Слави нашого міського кладовища.",
    ],
  },
  {
    id: 25,
    name: "Олег Миколайович БОНДАРЕНКО",
    date: "19.03.1977 - 13.09.2023",
    photo: photo25,
    position: "солдат ",
    texts: [
      "13 вересня перестало битися серце нашого земляка Олега Миколайовича БОНДАРЕНКА.",
      "Він був на «нулі» під Бахмутом, брав участь в боях під Оріховим Запорізької області. Було Герою лише 46 років… ",
      "Народився Олег БОНДАРЕНКО у місті Вільногірськ 19 березня 1977 року. Навчався у середній школі №5, потім у місцевому професійно-технічному училищі №4. ",
      "За плечами Олега Миколайовича служба в армії, робота на Вільногірському гірничо-металургійному комбінаті, у ТОВ «Вільногірське скло».  ",
      "У квітні 2022 року пішов на фронт по мобілізації. Був поранений, переніс тяжку контузію. Цими днями проходив реабілітацію вдома, у рідному Вільногірську. На жаль, земне життя Олега обірвалося передчасно. Збережімо його образ, його ім’я у нашій пам’яті.",
      "Працелюбний, доброзичливий, оптимістичний – саме таким його запам’ятають назавжди друзі і колеги по роботі.  ",
      "Сміливим і відданим батьківщині воїном залишиться він назавжди у пам’яті бойових побратимів.",
      "Найважче зараз рідним: мамі, братові, дружині, доньці. Страшна втрата, невтішне горе спіткало сім’ю. Від усієї громади висловлюємо їй щирі співчуття. І низький уклін нашому захисникові…",
      "Вічна і світла пам’ять Олегові Миколайовичу БОНДАРЕНКУ. ",
      "Слава йому і всім нашим співвітчизникам, які платять таку страшну ціну за нашу свободу, за мир в Україні.  ",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно). ",
    ],
    sliderImg: [],
  },
  {
    id: 62,
    name: "Віктор Олександрович БУТЕНКО",
    date: "15.05.1985 - 23.07.2024",
    photo: photo62,
    position: "молодший сержант",
    texts: [
      "росія продовжує вбивати синів і доньок України, цвіт нації, працьовитих, миролюбних громадян, яким довелося взяти зброю в руки і стати на захист рідної держави. ",
      "Ще одна така гірка втрата у нашій громаді: під час виконання бойового завдання на фронті загинув Віктор Олександрович БУТЕНКО. ",
      "Народився Віктор 15 травня 1985 року у місті Вільногірськ. Навчався у Вільногірській загальноосвітній школі №5. ",
      "Спеціальність гірничого інженера здобув у Національному гірничому університеті (зараз НТУ «Дніпровська політехніка»). ",
      "Після навчання працював за фахом у ТОВ «Мотронівський ГЗК». Проявив себе гарним працівником, користувався авторитетом серед колег по роботі. Разом із дружиною Катериною Віктор створив міцну гарну родину, виховували двох синочків, будували мирні плани на майбутнє, як і мільйони інших співвітчизників. ",
      "Але країна-загарбниця змінила життя багатьох українців, розгорнувши проти нас широкомасштабну війну. Вже 23 грудня 2022 року молодший сержант Віктор Олександрович БУТЕНКО був призваний Першим відділом у місті Верхньодніпровск Кам’янського РТЦК та СП на військову службу по мобілізації. Служив техніком однієї зі стрілецьких рот військової частини А****. ",
      "Брав участь у боях на Лиманському напрямку, в районі Серебрянського лісництва, під Часовим Яром, на Покровському напрямку на Донеччині. За час служби двічі був важко поранений, пройшов лікування у госпіталях. Військові побратими згадують Віктора як вірного друга, мужнього і звитяжного бійця, який був справжнім Воїном і гідним Громадянином своєї держави.",
      "23 липня 2024 року, виконуючи чергове бойове завдання, Віктор Олександрович потрапив під ворожий обстріл, в результаті чого отримав поранення, несумісне з життям.",
      "Це сталося на околицях населеного пункту Юріївка Бахмутського району Донецької області. Вже без ознак життя бойові побратими винесли Віктора з поля бою. ",
      "Вільногірська громада висловлює щирі глибокі співчуття родині нашого земляка: батькам, дружині, синам, сестрі, а також всім друзям, колегам по роботі, побратимам Віктора. ",
      "За бажанням родини поховали земляка у селі Мар’янівка Дмитрівського старостинського округу на місцевому кладовищі. ",
    ],
  },

  {
    id: 5,
    name: "Віктор Олексійович ВЕРХОВОДОВ",
    date: "09.05.1967 - 24.11.2022",
    photo: photo5,
    position: "гранатометник",
    texts: [
      "Із жалем і болем ми проводжаємо у засвіти наших дорогих захисників. Кожна людська втрата крає мов ніж серця українців. І з кожною смертю лише зростає ненависть до країни-окупанта, країни-злочинця. ",
      "Віктор Олексійович ВЕРХОВОДОВ, світла, весела, оптимістична людина. Він завжди підбадьорював інших у важкі хвилини. Таким його і запам’ятаємо. ",
      "Народився Віктор 09 травня 1967 року в місті Лозова Харківської області. На честь свята перемоги батьки назвали хлопчика Віктор, що у перекладі златинськогоVictorозначає«переможець». ",
      "Після школи Віктор навчався у технікумі залізничного транспорту в місті Слов'янськ Донецької області. Працював слюсарем-авторемонтником, заступником керівника одного з рибгоспів.  ",
      "Як законослухняний громадянин, Віктор Олексійович пройшов армійську підготовку бойового піхотинця в Білорусії. ",
      "У Вільногірськ разом із родиною переїхав у 2001 році. Працював електриком у Палаці культури та спорту «Металург», охоронцем у супермаркеті «АТБ», слюсарем у СК «Аванґард».",
      "Коли у лютому поточного року розпочалась широкомасштабна агресія росіян проти України, Віктор Олексійович ВЕРХОВОДОВ вступив у добровольче формування Вільногірської територіальної громади. А 15 вересня був призваний ІІ відділом Кам’янського РТЦК та СП на військову службу за призовом по мобілізації.",
      "Він служив гранатометником у славетній 93-й бригаді «Холодний ЯР». Бої, у яких брав участь наш земляк, точаться майже в тих місцях, де пройшла його молодість, його студентські роки. ",
      "24 листопада 2022 року, виявивши стійкість і мужність,сержант ЗСУ Віктор Олексійович ВЕРХОВОДОВ героїчно загинув під час виконання бойового завдання в районі населеного пункту Бахмут Донецької області. ",
      "У Лозовій живуть його старенька мама та рідна сестра, яка втратила на цій війні чоловіка. У Вільногірську у Віктора залишились дружина, діти, рідні та близькі люди, колеги по роботі, друзі. Висловлюємо всім їм наші щирі співчуття.",
      "Вічна світла пам’ять воїну-визволителю! ",
      "Указом Президента України № 86/2023 «за особисту мужність, виявлену у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Віктора Верховодова  нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [verhovodov1, verhovodov2, verhovodov3],
  },

  {
    id: 7,
    name: "Віталій Олександрович ВОРОБЙОВ",
    date: "04.02.1975 - 11.01.2023",
    photo: photo7,
    position: "старший сапер-гранатометник інженерно-саперного взводу",
    texts: [
      "У нашу громаду знову надійшла сумна звістка відвійськових про загибель на передовій ще одного нашого земляка – Віталія Олександровича ВОРОБЙОВА... Ще одне життя і в ньому цілий світ.",
      "Народився Віталій 04.02.1975 року в селі Лихівка П'ятихатського району. Закінчив Вільногірську середню школу  № 3. Після служби в армії вивчився на електрогазозварника. ",
      "Багато років трудової діяльності віддав виробництву. Завжди працював за фахом, ветеран праці філії ВГМК АТ ОГХК. Зарекомендував себе як  висококласний спеціаліст, знавець своєї справи. Мав VI розряд, неодноразово перемагав у конкурсі на кращого молодого електрогазозварника комбінату. ",
      "Віталій завжди відзначався добродушним, оптимістичним характером на роботі і поза її межами. Був добропорядним сім'янином, а також відповідальним і гідним громадянином своєї держави. ",
      "У 2014 році пішов добровольцем на фронт, був учасником АТО. За першим дзвінком з військомату пішов боронити батьківщину в 2022 році.",
      "Віталій Олександрович ВОРОБЙОВ, старший сапер-гранатометник інженерно-саперного взводу однієї з військовихчастин ЗСУ, загинув 11 січня 2023 року під час виконання бойового завдання неподалік населеного пункту Кліщїївка Бахмутського району Донецької області. Як тисячі наших славетних воїнів, він віддав життя за визволення України від окупантів, за її територіальну цілісність і суверенітет.  ",
      "Низький уклін Герою і глибокі співчуття від усієї громади дружині Віталія Олександровича Тетяні Анатоліївні, всім його близьким, друзям, побратимам, колегам по роботі. ",
      "Вічна світла пам'ять і царство небесне Віталію Олександровичу ВОРОБЙОВУ. ",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 61,
    name: "Володимир Олегович ГАПОНЧУК.",
    date: "17.04.1971 - 28.05.2024",
    photo: photo61,
    position: "солдат",
    texts: [
      "Наша громада втратила ще одного захисника, справжнього Воїна і Героя своєї країни. 28 травня 2024 року зупинилося серце Володимира Олеговича ГАПОНЧУКА.",
      "Народився Володимир у селищі Ареда Читинської області 17 квітня 1971 року. Навчався у середній школі №3 міста Вільногірськ. Здобув спеціальність збагачувальника у місцевому металургійному технікумі.",
      "За плечима Володимира строкова армійська служба, досвід роботи підривником на одній із шахт міста Дзержинськ Донецької області.",
      "Побратимам Володимир ГАПОНЧУК відомий за позивним «Юг», пов’язаним із його службою у 90-х роках в миротворчих військах в Югославії.",
      "За мирних часів він працював у ТОВ «Вільногірське скло» та на Вільногірському гірничо-металургійному комбінаті.",
      "Вірний своїй країні і народові, з 2015 по 2019 роки брав участь в АТО і ООС. Коли 2022 році розгорнулася широкомасштабна російська агресія проти України,вже 25 лютого він, мобілізований першим відділом у місті Верхньодніпровську Кам'янського РТЦК та СП Дніпропетровської області, прибув у військову частину.",
      "Служив у славетній 93-й окремій механізованій бригаді «Холодний Яр». Визволяв Тростянець, Охтирку Сумської області, Ізюм Харківської області. Брав участь у боях за Бахмут і Часів Яр Донецької області. ",
      "Наприкінці 2023 року був комісований за станом здоров’я, отримав 3 групу інвалідності.Всі роки був активним членом громадської організації «Об’єднання учасників АТО Вільногірська», гарним і надійним другом своїм однодумцям і побратимам.",
      "28 травня 2024 року Володимир Олегович помер раптово вдома, у Вільногірську.Вся громада, ГО «Об’єднання учасників АТО Вільногірська» висловлюють щирі співчуття дружині, мамі і синові нашого земляка, всім його друзям і колегам по роботі.Вдячність тобі, Герою, за подвиг в ім’я миру і свободи, в ім’я людяності і добра.",
      "Низький уклін!Сумно і болісно, що війна не щадить кращих синів і доньок…",
    ],
  },
  {
    id: 41,
    name: "Євген Леонідович ГОЛОВІН",
    date: "24.01.1976 - 04.10.2023",
    photo: photo41,
    position: "командир кулеметного відділення",
    texts: [
      "04 жовтня під час виконання бойового завдання на передовій загинув вільногірець Євген Леонідович ГОЛОВІН, солдат, командир кулеметного відділення кулеметного взводу стрілецької роти стрілецького батальону однієї з військових частин ЗСУ. ",
      "Євген народився 24 січня 1976 року в селищі Октябрьське Красногвардійського району Автономної республіки Крим. Згодом родина Головіних переїхала у Вільногірськ. Євген навчався у Вільногірській середній школі #4, а потім у вечірній. Їздив на заробітки, працював у ТОВ 'Вільногірське скло', на філії 'ВГМК' АТ 'ОГХК', у ТОВ 'Мотронівський ГЗК'.",
      "Розпочалась широкомасштабна збройна агресія росії проти України. 16 лютого 2023 року він був призваний на військову службу першим відділом Кам'янського РТЦК та СП Дніпропетровської області. Як і тисячі наших співітчизників, Євген став на захист рідної землі. ",
      "Виявивши стійкість та мужність під час захисту суверенітету та територіальної цілісності України, він загинув в бою в районі населеного пункту Кучерів Яр Донецької області. ",
      "Як гідний син своєї країни, Євген Леонідович до останньої хвилини життя був вірним військовій присязі.",
      "У нього залишилась мати та дві доньки. ",
      "Від усієї громади висловлюємо щирі співчуття родині, друзям, колегам по роботі, побратимам Євгена.",
      "Низький уклін матері Воїна Світлані Кузьмівні. ",
      "Пам'ять про нього житиме у віках.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 36,
    name: "Денис Григорович ГОЛУБЄВ ",
    date: "12.03.1983 - 07.02.2015",
    photo: photo36,
    position: "прапорщик",
    texts: [
      "Голубєв Денис Григорович (12 березня 1983 — 7 лютого 2015) — прапорщик Збройних сил України, учасник російсько-української війни.",
      "Закінчив Вільногірську ЗОШ № 5 у 2000 році, потім Севастопольський військово-морський коледж, мічман. З 2005 року працював на Вільногірському ГМК слюсарем з контрольно-вимірювальних приладів та автоматики. ",
      "У 2012 році закінчив Державний вищий навчальний заклад «Національний гірничий університет» і отримав повну вищу освіту за спеціальністю  Інформаційні управляючі системи та технології та здобув кваліфікацію професіонала в галузі обчислювальних систем.",
      "З серпня 2014-го служив в лавах ЗСУ, командир відділення зв'язку, 17-та окрема танкова бригада.",
      "7 лютого 2015-го загинув під час мінометного обстрілу взводного опорного пункту поблизу Станиці Луганської. Без чоловіка залишились: вагітна на той час дружина, мати та брат.  ",
      "Це перший житель м. Вільногірськ, який загинув в російсько-українській війні.",
      "Похований у місті Вільногірськ 12 лютого 2015 року. ",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі під час російсько-української війни нагороджений орденом «За мужність» III ступеня (посмертно) згідно з Указом Президента України від 3 травня 2015 року № 282/2015 «Про відзначення державними нагородами України».",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 53,
    name: "Сергій Борисович ГОЛОДНИК",
    date: "06.09.1985 - 15.01.2024",
    photo: photo53,
    position: "солдат",
    texts: [
      "Клятий ворог забрав життя ще одного воїна, вірного сина України.",
      "15 січня 2024 року в бою за свободу і незалежність нашої держави загинув солдат Сергій Борисович ГОЛОДНИК.",
      "Сергій Борисович - уродженець Кіровоградщини. Народився 06 вересня 1985 року. Так сталося, що з перших днів життя Сергій був позбавлений родинного затишку.",
      "Виховання і загальну освіту отримав в дитячому будинку смт Пантаївка Олександрійського району Кіровоградської області.",
      "В Ерастівському технікумі (Вишнівська ОТГ, Кам’янський район Дніпропетровської області) отримав освіту агронома. Вже у дорослому віці розшукав рідну бабусю, доглядав її до смерті. Працював у ТОВ «Вільногірське скло», ТОВ ІІ «Цвєтмєт», на дільниці газової служби.",
      "У місті Вільногірськ мешкають зараз його двоє дітей та теща. Громадянська дружина померла.",
      "Сергій ГОЛОДНИК призваний на військову службу по мобілізації 22 вересня 2023 року Кропивницьким МТЦК та СП Кіровоградської області.",
      "Служив стрільцем-помічником гранатометника в аеромобільному батальйоні 95-ї десантно-штурмової бригади.",
      "За словами командира частини, під час несення служби зарекомендував себе з позитивної сторони: функціональні обов’язки виконував відмінно, користувався певним авторитетом серед побратимів. Мав високий рівень мотивації до військової служби та самовдосконалення.",
      "Завжди підтримував моральний дух солдатів у підрозділі, стійко переносив інтенсивні навантаження…",
      "Зрозуміло, що за скупими словами військових криється набагато більше інформації, про яку нам, людям, які живуть не у прифронтовій зоні, навіть важко уявити.",
      "І мотивація у Сергія, як і у всіх наших захисників, більш ніж зрозуміла: врятувати життя дітей, вибити ворога зі своєї батьківщини, повернути мир на рідну землю.",
      "На жаль, Сергій загинув у бою. Це сталося поблизу Мар’їнки Покровського району Донецької області.",
      "До останньої хвилини свого життя солдат залишився вірним військовій присязі.",
      "Ціна перемоги висока і страшна – наші люди. Висловлюємо глибокі і щирі співчуття його дітям, тещі Наталії Олександрівні ПЛАХОТІ, яка відтепер є опікуном онуків, друзям, побратимам військовослужбовця. Поховали Сергія Борисовича ГОЛОДНИКА на Алеї Слави міського кладовища.",
      "Вічна слава і світлий спомин Героєві!",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
      "Указом Президента України № 352/2024 від 17.06.2024 за особисту мужність, виявлену у захисті державного суверенітету та територіальної цілісності України, самовіддане виконання військового обов’язку Сергія ГОЛОДНИКА нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
    ],
  },
  {
    id: 46,
    name: "Сергій Валерійович ГОРОБЕЦЬ ",
    date: "03.12.1986 - 17.10.2014",
    photo: photo46,
    position: "старший стрілець",
    texts: [
      "Сергій Валерійович Горобець народився 03 грудня 1986 року у м. Вільногірськ Дніпропетровської області в родині будівельників. 1988 року сім’я переїхала до міста Южноукраїнськ Миколаївської області.",
      "Сергій закінчив місцеву загальноосвітню школу №3. Навчався в Кіровоградському військовому ліцеї, Севастопольському військово-морському ліцеї. Займався спортом, кандидат у майстри спорту з дзюдо.",
      "Строкову військову службу пройшов у Військово-Морських Силах Збройних Сил України. Деякий час служив у взводі спецпризначення «Скорпіон» в/ч 3044 з охорони об'єктів енергокомплексу.",
      "У червні 2014-го зарахований до 34-го батальйону територіальної оборони «Батьківщина» як доброволець. Старший стрілець, 34-й батальйон територіальної оборони «Батьківщина». Брав участь у звільненні міста Дзержинська Донецької області від терористичних угруповань.",
      "17 жовтня 2014-го загинув на блокпосту № 7 під селом Озерянівка (неподалік від м. Горлівка Донецької області) — снайпер відкрив вогонь із лісосмуги.",
      "Похований в місті Южноукраїнськ. На честь Сергія Горобця на фасаді Южноукраїнської ЗОШ №3 встановлено пам'ятну дошку.",
      "Указом Президента України № 311/2015 від 04.06.2015 року «за особисту мужність і високий професіоналізм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Сергія нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "Нагороджений відзнакою Громадської Спілки «Всеукраїнське об’єднання «Ми українці» - орденом «Хрест Героя» (посмертно).",
      "Портрет на меморіалі «Стіна пам’яті полеглих за Україну» у м. Київ: секція 4, ряд 5, місце 33.",
    ],
  },
  {
    id: 45,
    name: "Ростислав Олексійович ДАНІЛОВ",
    date: "19.06.1990 - 08.11.2017",
    photo: photo45,
    position: "кулеметник 2-ї парашутно-десантної роти ",
    texts: [
      "Народився 1990 року в селі Велижани (Тюменська область РСФСР). Переїхав з матір'ю на Дніпропетровщину, з малих років мешкав у П'ятихатках. ",
      "Від 1996 року його вихованням разом із матір'ю займався вітчим. Закінчив середню школу у П'ятихатках, вступив до ВПУ у Жовтих Водах, здобув фах токаря. ",
      "Працював за спеціальністю у Дніпрі, потім перейшов до локомотивного депо у П'ятихатках, працював зварювальником. Захоплювався автомобілями, мототехнікою та історичною літературою, любив бойові породи собак.",
      "Після 1,5 років строкової служби у 169-ому навчальному центрі «Десна» імені князя Ярослава Мудрого 1 листопада 2016 року підписав контракт і вже за 10 днів був у зоні бойових дій. Солдат, кулеметник 2-ї парашутно-десантної роти 1-го парашутно-десантного батальйону 25-тої окремої повітрянодесантної Січеславської бригади. ",
      "У серпні 2017-го повернувся з фронту на ротацію, одружився, а вже 22 жовтня відбув на передову.",
      "8 листопада 2017 року близько опівдня зазнав смертельного поранення у живіт від кулі снайпера  під час вогневого протистояння з противником в районі шахти «Бутівка». Помер дорогою до лікарні.",
      "11 листопада 2017-го похований на Шевченківському кладовищі міста Дніпро.",
      "Без Ростислава залишились мама, два сина.",
      "Указом Президента України № 42/2018 від 26 лютого 2018 року, «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, зразкового виконання військового обов'язку», нагороджений орденом «За мужність» III ступеня (посмертно). ",
      "14 травня 2021 року на фасаді П'ятихатської загальноосвітньої школи № 1, де навчався Ростислав, відкрито меморіальну дошку на його честь.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 69,
    name: "Олександр Миколайович ДВОРНИК",
    date: "21.11.1974 - 29.10.2024",
    photo: photo69,
    position: "Солдат-сапер",
    texts: [
      "Війна чорним птахом принесла горе ще в одну родину нашої громади. ",
      "На фронті, захищаючи Україну від російської навали, героїчно загинув наш земляк Олександр Миколайович ДВОРНИК. ",
      "Олександр народився 21 листопада 1974 року в місті Ніжин Чернігівської області. Згодом родина переїхала у село Боровківку (на той час Верхньодніпровського району Дніпропетровської області). ",
      "Саме там Олександр закінчив 9 класів місцевої школи і пішов працювати у Вільногірськ на будівництво. Після служби в армії повернувся до будівництва нашого міста, зокрема одним з останніх об'єктів, де він трудився, був будинок №15 по бульвару Миру. Працював слюсарем. ",
      "Коли для країни постала загроза від рф, без коливань пішов захищати батьківщину. У 2014-2015 роках брав участь в АТО, відстоюючи територіальну цілісність і незалежність нашої держави. Потім продовжив трудовий шлях на Вільногірському ГМК: на збагачувальній фабриці, у ЦВП, пульпонасосній станції. ",
      "Останнім часом, перед широкомасштабним вторгненням росіян в Україну, працював бульдозеристом на ГТВ. Як згадують друзі, рідні і колеги Олександра Миколайовича, він завжди любив людей, його також обожнювали малі і дорослі. ",
      "Безкорисливий і чуйний, завжди готовий був ділитися з ближніми останнім. ",
      "Знав сотні приказок, володів неабияким почуттям гумору. З 24 лютого 2022 призваний першим відділом у м. Верхньодніпровськ Кам'янського РТЦК та СП на військову службу по мобілізації. ",
      "Солдат-сапер з позивним <Соколик>, Олександр Миколайович ДВОРНИК пройшов великий і складний військовий шлях: боронив Український народ на Запорізькому і Херсонському напрямках, воював за Бахмут, за Лиман Донецької області. 29 жовтня 2024 року в результаті атаки ворожими FPV-дронами наш земляк загинув в районі населеного пункту Терни Краматорського району Донецької області. Загинув на полі бою, до останнього подиху залишаючись вірним сином свого народу, своєї землі. ",
      "Вільногірська міська рада від імені всієї територіальної громади висловлює щирі співчуття близьким Олександра Миколайовича: мамі Євдокії Степанівні, дружині Олені Миколаївні, доньці Анастасії, а також всім, хто знав нашого земляка, працював або воював пліч-о-пліч із ним. ",
      "Поховали земляка у Вільногірську на Алеї Слави міського кладовища. Вічна слава і світла пам'ять воїну!",
    ],
  },
  {
    id: 51,
    name: "Ростислав Леонідович ДЗЮБА",
    date: "02.01.1971 - 13.12.2023",
    photo: photo51,
    position: "молодший сержант ЗСУ",
    texts: [
      "Трагічна звістка надійшла у нашу громаду від командування однієї з військових частин ЗСУ: 13 грудня 2023 року відійшов у засвіти молодший сержант ЗСУ, вільногірець Ростислав Леонідович ДЗЮБА. ",
      "Ростислав народився 02 січня 1971 року у місті Вільногірськ. Навчався у середній школі №3.",
      "Після закінчення курсів ДТСААФ розпочав трудову діяльність автослюсарем на Вільногірському ГМК.",
      "Два роки віддав армійській службі. Отримав звання молодшого сержанта.",
      "Трудова біографія нашого земляка за різних часів пов'язана із ВГМК, підприємством 'Азот' м. Кам'янське, приватними підприємствами 'Гарант' і 'Рассвет', ТОВ 'Скляний Альянс', приватною діяльністю.",
      "Широкомасштабна російська агресія вплинула на долі більшості громадян України. ",
      "Ростислав Леонідович, як і тисячі співвітчизників, готовий був зробити свій особистий внесок у захист незалежності та територіальної цілісності нашої держави. 11 грудня він прибув у розпорядження військової частини. Днями повинні були розпочатись навчання. ",
      "На жаль, 13 грудня 2023 року лікарі констатували смерть військовослужбовця в результаті захворювання.",
      "Вільногірська громада висловлює щирі співчуття сестрі та синові покійного, його друзям та колишнім колегам по роботі.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 8,
    name: "Іван Володимирович ДОРОЩЕНКОВ",
    date: "21.07.1987 - 14.03.2022",
    photo: photo8,
    position: "",
    texts: [
      "На жаль, війна жорстока і невмолима.Ми прощаємося з нашим Героєм – Іваном Володимировичем ДОРОЩЕНКОВИМ. ",
      "Як сотні тисяч наших співвітчизників, він зі зброєю в руках пішов захищати свою землю, рідну батьківщину - Горлівку, в якій народився, Дніпропетровщину, де створив сім’ю.Загинув Іван в боях за Авдіївку.",
      "Ворог позбавив його можливості жити і радіти мирному небу, кохати дружину, ростити дитину, доглядати на старість батьків…",
      "Низький уклін йому за подвиг і вічна пам’ять!",
      "Указом Президента України № 236/2022 «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Івана Дорощенкова нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 9,
    name: "Віктор Васильович ЖАЛДАК ",
    date: "24.02.1963 - 20.07.2023",
    photo: photo9,
    position: "",
    texts: [
      "Пішов із життя наш земляк, учасник бойових дій російсько-української війни Віктор Васильович ЖАЛДАК. Дала взнаки тяжка хвороба, що сталася внаслідок війни.",
      "Народився Віктор 24 лютого 1963 року в селі Плоске П’ятихатського району Дніпропетровської області. Там же закінчив середню школу. У Дніпрозержинську (нині – м. Кам’янське) навчався в одному з професійно-технічних училищ, де здобув професію автомеханіка. ",
      "За плечами Віктора Васильовича служба в армії, робота в ЦГТТ Вільногірського гірничо-металургійного комбінату. Потім він займався автоперевозками як приватний підприємець.",
      "Колеги по роботі, друзі, місцеві жителі знали його не лише як надійного професіонала своєї справи, так і завжди привітну, безконфліктну, добродушну і порядну людину. ",
      "Люблячий чоловік, гарний батько і дідусь, Віктор Васильович пішов захищати найцінніших для себе людей, коли розпочалась широкомасштабна російська агресія. 04 квітня 2022 року його було призвано на військову службу по мобілізації. На фронт забрав і свою вантажівку. Служив у роті бойового забезпечення однієї з військових частин ЗСУ на Донеччині. Про жорстокі бойові дії в районі Часового Яру, Бахмуту він знав не з чуток. ",
      "У грудні 2022 року у Віктора Васильовича виявили невиліковне захворювання. Він дослужив до свого 60-річчя і у лютому був демобілізований за віком і як особа з інвалідністю внаслідок війни. ",
      "20 липня наш земляк відійшов у засвіти. У нього залишились дружина, два сини, рідний брат, онуки, племінники… Щирі співчуття всім рідним і близьким Віктора Васильовича.  ",
      "Справжній Воїн, гідний громадянин України завжди залишиться в народній пам’яті, в історії нашого краю і нашої держави.",
      "Світла пам’ять Герою!",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 10,
    name: "Олександр Валерійович ЖЕЛТОНОГ",
    date: "01.01.1990 - 15.02.2023",
    photo: photo10,
    position: "сапер інженерно-саперного взводу",
    texts: [
      "Ще одне молоде життя покладено на вівтар перемоги у цій клятій війні. Олександра знали як доброго сім’янина, гарного спеціаліста, добродушну товариську людину, завжди готову прийти на допомогу ближньому.",
      "Народився Олександр у Вільногірську. Навчався у середній школі №1, а потім здобував професію скловара в нашому професійно-технічному училищі.  ",
      "До 2022 року працював у товаристві «Скляний Альянс».",
      "31 серпня Олександра було призвано на службу по мобілізації. Як гідний громадянин своєї держави, він пішов боронити рідну землю. Служив сапером інженерно-саперного взводу мотопіхотного батальйону однієї з військових частин ЗСУ. ",
      "На жаль, 15 лютого 2023 року солдат Олександр Валерійович ЖЕЛТОНОГ загинув у бою під містом-фортецею Бахмут. Він залишився до кінця відданим військовій присязі на вірність Українському народові. ",
      "Від всієї громади висловлюєм глибокі і щирі співчуття всій родині воїна. Два дядька Олександра зараз також на передовій. Хай береже їх Господь від ворожої смерті. Хай дасть сили цій родині пережити страшну втрату. ",
      "Низький уклін Герою, наша вдячність і вічна пам’ять!",
      "Указом Президента України № 673/2023 від 29.09.2023 за особисту мужність, виявлену у захисті державного суверенітету та територіальної цілісності України, самовіддане виконання військового обов’язку Олександра Желтонога нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 42,
    name: "Ярослав Сергійович ЖУРАВЕЛЬ",
    date: "08.03.1980 - 13.07.2020",
    photo: photo42,
    position: "Сержант ЗСУ",
    texts: [
      "Ярослав Журавель народився 8 березня 1980 року у Верхньодніпровському районі Дніпропетровської області України.",
      "З весни 2015 року на російсько-українській війні. Служив у 20-му окремому мотопіхотному батальйоні 93-ї бригади, в 35-у окрему бригаду морської піхоти розвідник перевівся у 2019 році із 93 ОМБр «Холодний Яр» разом зі своїм, нині загиблим, командиром Дмитром Красногрудем.",
      "Пройшов такі «гарячі» точки, як Мар'їнка, Піски, Авдіївська промзона, Трьохізбенка, Гранітне.",
      "Ярослав був у групі із саперів та представників СЦКК, які вирушили забирати тіло командира розвідувального взводу Дмитра Красногрудя, що підірвався на міні. В евакуаційній групі, яку спорядили 13 липня, сержант Журавель йшов першим. Російські терористи почали обстрілювати групу евакуації, незважаючи на «режим тиші», узгоджений через ОБСЄ.",
      "Опісля обстрілу поранений Ярослав залишився на полі бою без допомоги. Три дні поспіль росіяни не давали проводити пошукову операцію. Востаннє живого Ярослава Журавля зафіксував дрон української розвідки. На оприлюдненому відео він у білій касці і жилеті сам собі зупиняв кровотечу джгутом та шукав укриття. Однак евакуювати бійця з поля бою не вдалося. 21 липня тіло сержанта було передано на контрольовану урядом України територію. ",
      "Похований Воїн 23 липня 2020 року в селі Андріївка на Дніпропетровщині, де народився і виріс. ",
      "3 серпня 2020 року була створена електронна петиція щодо надання Ярославу Журавлю звання «Герой України» (посмертно).",
      "5 серпня 2020 року Указом Президента України №304/2020 за особисту мужність i самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, нагороджений орденом «За мужність» III ступеня (посмертно).",
      "22.12.2020 року Печерський районний суд м. Києва задовольнив скаргу батька загиблого військовослужбовця Ярослава Журавля Сергія Журавля й зобов’язав Державне бюро розслідувань відкрити провадження щодо Верховного Головнокомандувача Збройних Сил України через загибель солдата.",
      "15 квітня 2021 року на фасаді рідної школи Ярослава встановлено і урочисто відкрито меморіальну дошку на знак вшанування подвигу Героя.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 44,
    name: "Юрій Сергійович ЖУК",
    date: "02.03.1981 - 17.06.2015",
    photo: photo44,
    position: "кулеметник 53-ї окремої механізованої бригади ",
    texts: [
      "Народився 2 березня 1981 року в селі Лозуватка П’ятихатського району Дніпропетровської області. Закінчив загальноосвітню школу села Лозуватка та Олександрійський державний аграрний технікум за спеціальністю «ветеринарна медицина» у місті Олександрія Кіровоградської області. ",
      "Працював ветеринарним фельдшером у колгоспі.",
      "Проходив строкову військову службу в лавах Збройних Сил України. Служив в 11-й окремій бригаді армійської авіації Сухопутних військ Збройних Сил України (військова частина А1604, селище міського типу Чорнобаївка Білозерського району Херсонської області).",
      "Після служби в армії працював водієм на дослідній станції, слюсарем, грохотником.",
      "Добровольцем мобілізований до лав Збройних Сил України. Пройшов трьохмісячну військову підготовку на полігоні біля міста Кривий Ріг Дніпропетровської області. Служив кулеметником 53-ї окремої механізованої бригади Сухопутних військ Збройних Сил України (військова частина В0927, міста Сєвєродонецьк/Лисичанськ Луганської області).",
      "Брав участь в антитерористичній операції на сході України. Позивний «Звіробій». Ніс службу на блок-посту «Сталінград» під селищем міського типу Станиця Луганська Луганської області.",
      "17 червня 2015 року солдат Жук загинув внаслідок підриву на розтяжці в районі взводно-опорного пункту №5311 у районі селища міського типу Верхньоторецьке Ясинуватського району Донецької області.",
      "Похований на кладовищі села Лозуватка П’ятихатського району Дніпропетровської області.",
      "Нагороджений орденом «За мужність» III ступеня (04.02.2016; посмертно).",
      "У Юрія залишились мати, дружина та син.",
      "Указом Президента України № 37/2016 « за мужність, самовідданість і високий професіоналізм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Юрія Жука нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
    ],
  },
  {
    id: 66,
    name: "Сергій Валерійович ЖУКОТИНСЬКИЙ",
    date: "12.03.2021 - 29.03.2024",
    photo: photo66,
    position: "гранатометник",
    texts: [
      "У глибокій скорботі Вільногірська громада, адже внаслідок збройної агресії росії проти України ми втратили ще одного земляка – Сергія Валерійовича ЖУКОТИНСЬКОГО.",
      "Ще одне життя українського громадянина, нашого захисника обірвала російська зброя.",
      "Сергій народився 12 березня 2001 року в м. Вільногірськ Дніпропетровської області. Навчався у загальноосвітній школі №5. Після 9 класу продовжив навчання у Криворізькому автотранспортному коледжі за спеціальностю «механік автотранспорту».",
      "Працював водієм у ЦГТТ філії «ВГМК» АТ «ОГХК», потім водієм-далекобійником.",
      "27 вересня 2023 року Сергія Валерійовича ЖУКОТИНСЬКОГО призвано на військову службу першим відділом Кам’янського РТЦК та СП м.Верхньодніпровськ Дніпропетровської області.",
      "Він був гранатометником в одному з аеромобільних батальйонів ЗСУ. Брав участь у захисті Куп’янська, Авдіївки, Бахмута.",
      "17 жовтня 2024 року від командира військової частини, у якій служив Сергій, надійшло повідомлення про загибель нашого земляка у бою за територіальну цілісність і незалежність нашої держави в районі населеного пункту Первомайське Покровського району Донецької області. ",
      "Це сталося 29 березня 2024 року…",
      "Солдат Сергій ЖУКОТИНСЬКИЙ до останнього подиху залишився вірним військовій присязі, Українському народові, громадянському обов’язку. ",
      "Схиляємо голови на знак пошани і світлої пам’яті про нашого земляка. Від всієї громади висловлюємо щирі співчуття мамі Сергія – Наталі Сергіївні, рідним, близьким воїна, його друзям і військовим побратимам.",
      "Поховали Сергія Валерійовича ЖУКОТИНСЬКОГО на Алеї Слави міського кладовища.",
    ],
  },
  {
    id: 43,
    name: "Костянтин Анатолійович КАРВАЦЬКИЙ",
    date: "20.12.1989 - 14.04.2023",
    photo: photo43,
    position: "стрілець-снайпер механізованого взводу",
    texts: [
      "Народився 20 грудня 1989 року в селі Касинівка П‘ятихатського району (нині – П’ятихатської міської об‘єднаної територіальної громади Кам’янського району) Дніпропетровської області.",
      "Закінчив загальноосвітню школу, у 2011 році – Дніпропетровський державний аграрний університет (нині – Дніпровський державний аграрно-економічний університет, місто Дніпро).",
      "З лютого 2015 року по березень 2016 року проходив військову службу в лавах Збройних сил України по частковій мобілізації. ",
      "Служив водієм. Протягом 2015-2016 років брав участь в антитерористичній операції на сході України.",
      "Проживав у місті Вільногірськ. Працював агрономом у товаристві з обмеженою відповідальністю «Агроцентр К».",
      "У березні 2022 року Першим відділом у місті Верхньодніпровськ Кам'янського районного територіального центру комплектування та соціальної підтримки Дніпропетровської області мобілізований до лав Збройних сил України. ",
      "Служив стрільцем-снайпером механізованого взводу механізованої роти механізованого батальйону однієї з військових частин Збройних сил України. Позивний «Кипиш».",
      "З 2022 року брав участь у боях з російськими окупантами. Учасник боїв за місто Бахмут Донецької області.",
      "14 квітня 2023 року солдат Костянтин Карвацький загинув під час виконання бойового завдання поблизу міста Бахмут Донецької області.",
      "18 квітня 2023 року похований на кладовищі села Осикувате П‘ятихатської міської об'єднаної територіальної громади Кам'янського району Дніпропетровської області.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 68,
    name: "Ігор Іванович КЛІЦЕНКО",
    date: "22.08.1969 - 19.10.2024",
    photo: photo68,
    position: "водій- механік",
    texts: [
      "Народився Ігор у Вільногірську 22 серпня 1969 року. Навчався у середній школі №4.",
      "Все своє доросле життя працював на Вільногірському ГМК: на збагачувальній фабриці, потім у ЦГТТ (ГТВ).",
      "Коли розпочалася широкомасштабна російська агресія проти України, Ігор Іванович вже 02 березня 2022 року був призваний першим відділом у м.Верхньодніпровськ Кам'янського РТЦК та СП Дніпропетровської області по мобілізації.",
      "Молодший сержант Ігор КЛІЦЕНКО був водієм- механіком у славетній 17 танковій штурмовій бригаді.",
      "Брав участь у боях на Донеччині за Бахмут, Часів Яр, визволяв Херсон. Йому було 55. (Дєд), так називали його бойові побратими, був прикладом гідності, мужності, чуйності і фронтової дружби та взаємопідтримки. 19 жовтня 2024 року під час ведення бойових дій у населеному пункті Ольгівка Курської області рф наш земляк отримав поранення, несумісні з життям.",
      "У воїна залишились: мама Валентина Семенівна, дружина Олена Геннадіївна, донька Анна, сестра Наталія. Не дочекався дідуся дворічний онучок...",
      "Висловлюємо глибокі і щирі співчуття рідним і близьким, друзям, колегам по роботі, бойовим побратимам Ігоря Івановича.",
      "Поховали Ігоря Івановича КЛІЦЕНКА на Алеї Слави міського кладовища",
          ],
  },
  {
    id: 52,
    name: "Денис Андрійович КОВАЛЕНКО",
    date: "31.01.1994 - 14.12.2023",
    photo: photo52,
    position: "старший стрілець стрілецької роти",
    texts: [
      "Скорботна звістка надійшла у Вільногірську громаду: на щиті повертається з війни ще один наш захисник, вірний син України Денис Андрійович КОВАЛЕНКО...",
      "Денис народився 31 січня 1994 року у селі Нескучне Волноваського району Донецької області.",
      "Навчався у місцевій школі. Професію тракториста - машиніста широкого профілю здобув у Великоновосілківському професійно-технічному училищі. З юних років відзначався добродушним характером, завжди приходив на допомогу людям.",
      "Розпочав свою трудову діяльність на одному з промислових підприємств міста Курахово. Напевне, як і вся наша молодь, будував плани на майбутнє.",
      "Але війна перекреслила, змінила долю Дениса, не дала реалізувати його мрії.",
      "З перших днів широкомасштабної російської агресії Денис Андрійович вступив у місцевий загін територіальної оборони. Події розвивалися швидко. Вже 05 березня його перевели у військову частину. ",
      "Мирна працьовита людина стала солдатом, захисником Українського народу.Він брав участь у боях за Донецьку область. Рік воював на 'нулі'. ",
      "Служив старшим стрільцем стрілецької роти однієї з військових частин Збройних Сил України.",
      "14 грудня 2023 року, виконуючи бойове завдання, Денис загинув поблизу міста Красногорівка Покровського району Донецької області. ",
      "До останнього подиху він залишився вірним військовій присязі, виявивши стійкість і мужність, притаманну українським воїнам.",
      "Війна розпорядилася так, що зараз найрідніші Денисові люди - мама і дядько, проживають у Вільногірській міській територіальній громаді, мають статус внутрішньо переміщених осіб.",
      "Від імені всіх мешканців громади висловлюємо глибокі співчуття їм з приводу загибелі Дениса, співчуття всім його друзям, колегам по роботі, жителям Великоновосілківської селищної громади, фронтовим побратимам. Низький уклін мамі воїна Світлані Миколаївні за гідне виховання сина, справжнього громадянина своєї держави. ",
      "Вічна слава і світла пам'ять Герою, який віддав життя за всіх нас, за територіальну цілісність і суверенітет України!",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 57,
    name: "Сергій Валентинович КОМАРЕНКО",
    date: "06.11.1986 - 05.05.2023",
    photo: photo57,
    position: "молодший сержант",
    texts: [
      "Російсько-українська війна - найжорстокіша, найстрашніша в історії людства. Ніхто не може бути забутим у цій війні, жодна людина, жоден наш захисник. ",
      "05 травня 2023 року під містом Часів Яр загинув Сергій Валентинович КОМАРЕНКО. На віки він залишився 36-річним чоловіком, гідним громадянином України, який віддав за нас найцінніше – власне життя.",
      "Народився Сергій 06 листопада 1986 року в місті Рівне. Згодом родина переїхала на Приорілля – у село Ряське, що на Полтавщині. У місцевій школі педагоги пам’ятають його як дисциплінованого, відповідального учня, який цікавився точними науками, любив фізичну культуру, захоплювався технікою. А ще він завжди прагнув самостійності, на все мав власну думку.",
      "Після 9 класу Сергій вступив до Дніпропетровського політехнічного коледжу. Вивчився на механіка.",
      "Його трудова діяльність розпочалась у селі Саївка П’ятихатського району Дніпропетровської області. Там він одружився. У молодої сім’ї народилося три донечки.",
      "З 2010 року Сергій Валентинович працював у ТОВ «Вільногірське скло», переїхав у Вільногірськ. Тут одружився вдруге. У родині народилася дівчинка.",
      "За плечима Сергія Валентиновича і роки праці на філії «Вільногірський ГМК» АТ «ОГХК». Він був водієм БілАЗу на гірничо-транспортному виробництві.",
      "У 2015-2016 роках наш земляк добровільно став учасником АТО. Брав участь у боях за Авдіївку, Іловайськ. Служив артилеристом. Під час служби отримав звання молодшого сержанта.",
      "Знову повернувся у Вільногірськ. Знову пішов працювати на ГТВ ГМК. ",
      "Коли росія розпочала широкомасштабну війну проти нашої держави, практично відразу, з 26 лютого 2022 року, Сергій став на захист батьківщини за призовом Верхньодніпровського ТЦК та СП по мобілізації. Воював на Охтирському, Ізюмському напрямках. Потім, з кінця 2022 року, він брав участь у боях на Бахмутському напрямку. Служив водієм в інженерно-саперній роті 93-ї ОМБр ВСУ «Холодний Яр».",
      "05 травня 2023 року, захищаючи незалежність, суверенітет і територіальну цілісність України, Сергій Валентинович КОМАРЕНКО загинув під час виконання бойового завдання. ",
      "11 травня 2023 року воїна з почестями поховали у селі Ря́ське Михайлівської сільської громади Полтавського району Полтавської області. Таким було бажання його батьків. ",
      "Вільногірська громада висловлює щирі співчуття всім рідним Сергія, його друзям, колегам по роботі, бойовим побратимам. Низький уклін батькам Героя.",
      "Указом Президента України від 29.09.2023 року № 673/2023 «за особисту мужність, виявлену у захисті державного суверенітету та територіальної цілісності України, самовіддане виконання військового обов’язку» Сергія КОМАРЕНКА нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області від 22 лютого 2024 року № 1426-59/VIII Сергієві Валентиновичу КОМАРЕНКУ присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно). ",
      "Вічна слава безсмертному подвигу воїна!",
    ],
    sliderImg: [komar1, komar2, komar3, komar4, komar5],
  },
  {
    id: 11,
    name: "Володимир Анатолійович КОСТОМАРОВ",
    date: "03.07.1977 - 02.09.2022",
    photo: photo11,
    position: "командир аеромобільного взводу аеромобільного батальйону",
    texts: [
      "Війна – жорстока реальність. Щодня, здобуваючи перемогу і право на життя свого народу, гинуть наші співвітчизники – чиїсь сини, чоловіки, батьки, брати…",
      "Наша громада знову у скорботі. 02 вересня 2022 року у бою під населеним пунктом Мар’їнка Донецької області героїчно загинув Володимир Анатолійович КОСТОМАРОВ. ",
      "Володимир Костомаров народився у Луганську 03 липня 1977 року. Там закінчив школу, потім навчався на гірничого електромеханіка в гірничій академії міста Дніпро. У студентські роки зустрів своє кохання – нашу землячку Світлану Броннікову. Так народилася нова сім’я. Отримавши дипломи, родина Костомарових поїхала працювати на батьківщину чоловіка. У Луганську народилася і донька Мирослава. ",
      "2014 року розпочались воєнні дії на території Луганської та Донецької областей. Війна зруйнувала життя багатьох сімей, треба було виживати і починати все з нуля. Костомарови поїхали працювати у Київ, а доньку привезли у Вільногірськ, до бабусі. Дівчинка знайшла нових друзів, новий шкільний колектив у ЗОШ №3 (зараз це Вільногірський ліцей №3).",
      "25 лютого 2022 року російські бомбардування змусили подружжя Костомарових виїхати з Києва у Вільногірськ. З перших днів перебування у нашому місті Володимир Анатолійович не стояв осторонь спільних проблем громади. Разом з іншими жителями нашого міста розчищав і облаштовував укриття у підвальних приміщеннях житлових будинків та адміністративних будівель.",
      "17 березня 2022 року Володимир звернувся у І відділ у м. Верхньодніпровськ Кам’янського РТЦК та СП щодо взяття на військовий облік для подальшого проходження служби, а 19 березня його вже було мобілізовано. ",
      "02 вересня 2022 року, виявивши стійкість і мужність у боях з російським агресором, командир аеромобільного взводу аеромобільного батальйону однієї з військових частин ЗСУ, старший лейтенант Володимир Анатолійович Костомаров загинув у ході бойових дій поблизу населеного пункту Мар’їнка Донецької області.",
      "Принциповий, відповідальний, чесний і мужній, він з гідністю виконав свій громадянський і військовий обов’язок щодо оборони України, захисту безпеки населення та інтересів держави.",
      "У Володимира залишилися жінка, мати, донька, сестра. Щирі співчуття родині, всім близьким і друзям загиблого воїна.",
      "Указом Президента України № 433/2023 від 18 липня 2023 року «Про відзначення державними нагородами України» за особисту мужність, виявлену у захисті державного суверенітету та територіальної цілісності України, самовіддане виконання військового обов’язку Володимира Анатолійовича КОСТОМАРОВА нагороджено орденом Богдана Хмельницького ІІІ ступеню (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 70,
    name: "Володимир Анатолійович КОСТЮШИН",
    date: "16.09.1970 - 01.11.2024",
    photo: photo70,
    position: "Старший солдат",
    texts: [
      "Ще одна безцінна втрата… ",
      "Володимир народився 16 вересня 1970 року в селі Кринички на той час Верхньодніпровського району Дніпропетровської області. ",
      "Після завершення навчання у Вільногірській середній школі №1 навчався у місцевому технікумі, пройшов армійську підготовку. Потім розпочав свою трудову діяльність. ",
      "Деякий час працював у ТОВ «Вільногірське скло». Багато років свого життя віддав праці у сфері ЖКГ. Мешканці громади добре знають Володимира Анатолійовича як гарного спеціаліста, висококласного слюсаря. Він користувався заслуженим авторитетом серед колективу комунального підприємства «УП «Жилкомсервіс», завжди був готовий прийти на допомогу. Завжди оптимістичний, безкорисливий, добрий, вдома – люблячий батько і чоловік. Дуже любив сільське життя, природу, риболовлю… ",
      "Таким був наш земляк, таким його запам’ятають люди. російська агресія змусила багатьох наших співгромадян взяти в руки зброю, щоб захистити своїх рідних, свою землю, свою державу. На початку вересня 2024 року першим відділом у м.Верхньодніпровськ Кам’янського РТЦК та СП було призвано по мобілізації й нашого земляка. ",
      "Старший солдат Володимир Анатолійович КОСТЮШИН загинув під час виконання бойового завдання 01 листопада 2024 року в районі населеного пункту Шипилівка Сєвєродонецького району Луганської області. Військовослужбовець до останнього подиху залишився вірним і відданим Україні. ",
      "Вільногірська громада висловлює глибокі співчуття родині загиблого: дружині Наталі Миколаївні, синові Владиславу, а також всім друзям, колегам і побратимам Володимира Анатолійовича. Низький уклін Героєві за мужність, звитягу і стійкість, з якими він став на наш захист. ",
      "Поховали Володимира Анатолійовича КОСТЮШИНА на Алеї Слави Вільногірського міського кладовища.",
      "Вічна пам’ять і світлий спомин воїну!",
      ,
    ],
  },

  {
    id: 13,
    name: "Валерій Володимирович КУКСА",
    date: "18.09.1972 - 22.08.2022",
    photo: photo13,
    position: "сапер інженерно-саперного взводу мотопіхотного батальйону ",
    texts: [
      "Ще одна болісна втрата, ще одне життя… У боях за Україну загинув житель Вільногірська Валерій Володимирович КУКСА.",
      "Народився Валерій у нашому місті 18 вересня 1972 року. Після закінчення середньої школи №2 навчався у нашому професійно-технічному училищі на зварника. За спеціальністю багато років відпрацював на Вільногірському гірничо-металургійному комбінаті. Завжди користувався певним авторитетом серед колег по роботі, мав добрий, поступливий характер. ",
      "У Вільногірську створив міцну і щасливу сім’ю, в якій народилося двоє синів. Завжди був дуже уважним та люблячим чоловіком та батьком. ",
      "Коли розпочалась активна фаза російської агресії, вже 2 березня він за призовом по мобілізації пішов захищати від ворога рідну землю. Служив сапером інженерно-саперного взводу мотопіхотного батальйону однієї з наших військових частин. ",
      "До останнього дня свого життя воїн залишався вірним військовій присязі. ",
      "22 серпня 2022 року солдат Валерій Володимирович КУКСА отримав поранення, несумісне з життям. Це сталося під час артилерійського обстрілу з боку російських військ під населеним пунктом Зайцеве Бахмутського району Донецької області. Смертю хоробрих загинув наш земляк, виконуючи своє бойове завдання…",
      "Вся громада висловлює щирі, глибокі співчуття родині Героя: дружині Світлані, дітям, сестрі, племінниці, а також колегам по роботі, всім, хто знав і товаришував з Валерієм Куксою.",
      "Вічна і світла пам’ять солдатові! Слава Герою! Слава Україні! ",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 58,
    name: "Юрій Миколайович КУКУРУДЗА",
    date: "22.02.1970 - 01.05.2024",
    photo: photo58,
    position: "Головний сержант",
    texts: [
      "З невимовним болем зустрічаємо скорботні звістки з фронтів. ",
      "У Вишнівську громаду надійшло сповіщення про героїчну смерть ще одного співвітчизника, нашого земляка – Юрія Миколайовича КУКУРУДЗИ, адже історія його життя безпосередньо пов’язана з Вільногірськом. ",
      "Юрій народився 22 лютого 1970 року в нашому місті. ",
      "Навчався у середній школі №2.",
      "За плечима земляка строкова служба в армії, робота на гірничо-металургійному комбінаті, у 52-й пожежно-рятувальній частині. З 2014 по 2017 рік він служив при Верхньодніпровському військоматі.",
      "Потім повернувся на промислове виробництво, працював газоелектрозварювальником у ТОВ «Мотронівський гірничозбагачувальний комбінат».",
      "Після смерті батьків переїхав з Вільногірська зі своєю родиною на постійне місце проживання у село Новоукраїнка Вишнівської селищної громади – свою малу батьківщину.",
      "З першого дня широкомасштабного вторгнення росії на територію України Юрія Миколайовича було призвано на військову службу по мобілізації ІІІ відділом в м. П’ятихатки Кам’янського РТЦК та СП.",
      "Головний сержант ЗСУ Юрій Миколайович КУКУРУДЗА мужньо і стійко боровся за територіальну цілісність, незалежність і суверенітет нашої держави. Служив захисник в одному з піхотних батальйонів Збройних Сил України, брав участь у боях на Авдіївському напрямку. ",
      "1 травня 2024 року він загинув в районі населеного пункту Очеретино Покровського району Донецької області, до останнього подиху залишаючись вірним військовому і громадянському обов’язку.",
      "Вільногірська громада висловлює щирі глибокі співчуття дружині Ніні Григорівні, синові Олексієві, який також стоїть на захисті України, всім близьким, друзям, колегам, бойовим побратимам воїна.",
    ],
  },
  {
    id: 65,
    name: "Роман Анатолійович ЛЕВЧЕНКО",
    date: "16.08.1985 - 24.08.2024",
    photo: photo65,
    position: "Солдат",
    texts: [
      "У кривавих боях російсько-української війни гинуть сини і доньки нашої держави.",
      "Ще одна страшна втрата і в нашій громаді, ще одне життя обірвали ворожі снаряди.",
      "Під час курської операції загинув житель Вільногірська Роман Анатолійович ЛЕВЧЕНКО. Народився Роман 16 серпня 1985 року в нашому місті.",
      "По завершенню навчання у Вільногірській середній школі №5 працював на гірничо-металургійному комбінаті озеленювачем, згодом освоїв професії електрика і слюсаря.",
      "Потім перевівся на ТОВ «Склянний Альянс». Працював слюсарем-ремонтником у составному цеху.",
      "Завжди був життєрадісним, позитивним як вдома, так і на роботі. Мав дружину, мріяв про дитину. Захоплювався механікою, малюванням, кресленням.",
      "Але все змінила війна…",
      "03.05.2024 року Роман був призваний на військову службу по мобілізації першим відділом у місті Верхньодніпровську Кам'янського РТЦК та СП Дніпропетровської області.",
      "Звичайний робітник став солдатом, пішов захищати суверенітет рідної країни.",
      "Пройшов військову підготовку на території області, ще місяць – за кордоном.",
      "За своє зовсім коротке воєнне життя бив ворога на Торецькому напрямку, в Сумській області. 24 серпня 2024 року він загинув під час бойових дій поблизу населеного пункту Мала Локня Суджанського району Курської області рф. «Я повернуся!» - такими були його останні слова дружині Олені.",
      "Він обов’язково повернеться до нас у віршах і прозі, уві снах і фільмах. Той подвиг, який здійснили тисячі наших співвітчизників під час незнаною за жорстокістю війни, залишиться в історії світу навіки. Від усієї громади висловлюємо щирі співчуття дружині і сестрі Романа Анатолійовича, всім його друзям, колегам по роботі, бойовим побратимам.",
      "Він назавжди залишиться в пам’яті народній як Герой, який віддав власне життя за мир і спокій в країні, за свободу і незалежність Українського народу.",
      "Низький йому уклін і наша шана. Романа Анатолійовича ЛЕВЧЕНКА поховали на Алеї Слави міського кладовища.",
    ],
  },
  {
    id: 14,
    name: "Віталій Вілійович ЛИСЯК",
    date: "18.09.1964 - 14.07.2023",
    photo: photo14,
    position: "Служив у роті вогневої підтримки однієї з фронтових бригад",
    texts: [
      "Скорботна звістка надійшла у нашу громаду від військових. 14 липня 2023 року, виконуючи свій громадянський і військовий обов’язок, загинув житель міста Вільногірськ Віталій Вілійович ЛИСЯК. ",
      "Віталій Вілійович народився у м. Вільногірськ 18 вересня 1964 року. Навчався в середній школі №3.Після школи навчався в нашому техникумі по спеціальності «Електрообладнання цивільних споруд». ",
      "Віталій ЛИСЯК працював багато років в «Дніпрообленерго», а потім на філії «Вільногірський гірничо-металургійний комбінат» АТ «ОГХК».",
      "З колегами по роботі мав завжди гарні професійні й товариські стосунки. Як фахівець у своїй справі, завжди користувався заслуженим авторитетом у колективі. ",
      "Віталій був гарним сім’янином, зразковим батьком і відданим сином своєї країни.  ",
      "Коли розпочалась широкомасштабна агресія з боку росії, він добровільно став на захист України. 15 березня 2022 року був мобілізований першим відділом у місті Верхньодніпровськ Кам’янського районного територіального центру комплектування та соціальної підтримки. Служив у роті вогневої підтримки однієї з фронтових бригад. ",
      "Стійко і мужньо захищаючи незалежність та територіальну цілісність батьківщини, 14 липня солдат ВСУ Віталій ЛИСЯК загинув у бою під селом Макарівка Волноваського району Донецької області. ",
      "Щирі співчуття родині воїна: дружині Ользі та синові Владиславу. Загиблому – низький уклін за ратний подвиг, за життя, покладене на вівтар нашої перемоги.",
      "Вічна пам’ять і невмируща слава вірним синам України.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 15,
    name: "Олександр Геннадійович ЛИТВАК",
    date: "26.06.1991 - 07.03.2023",
    photo: photo15,
    position: "номер обслуги артилерійського взводу артилерійської батареї ",
    texts: [
      "Сумна звістка надійшла у нашу громаду з Донеччини: знову втрата на передовій. Смертю хоробрих поліг на полі бою мешканець Вільногірська Олександр Геннадійович ЛИТВАК.",
      "Олександр народився у м. П'ятихатки 26 червня 1991 року. По закінченні загальноосвітньої школи 1 м. П'ятихатки вивчився на автослюсара. ",
      "11 років трудового стажу віддав роботі у ТОВ Вільногірське скло, декілька з них працював старшим налагодником верстатів з цифровим управлінням.",
      "Серед колег по роботі та друзів завжди користувався повагою за високу майстерність, добре серце та позитивний настрій.",
      "25 серпня 2022 року Олександр Геннадійович призваний І відділом Кам'янського РТЦК та СП Дніпропетровської області навійськову службу по мобілізації. Після двох місяців підготовки вступив у запеклу боротьбу проти російської навали. Служив номером обслуги артилерійського взводу артилерійської батареї десантно-штурмового батальйону однієї з військових частин ЗСУ.",
      "Вірний військовій присязі, з гідністю виконував бойові завдання, спрямовані на оборону України, захист безпеки населення та інтересів держави.",
      "Загинув у бою за нашу батьківщину в районі населеного пункту Мар'їнка Донецької області. ",
      "Висловлюємо щирі співчуття дружині полеглого Олені Миколаївні, сестрі, всім рідним, близьким, друзям, колегам по роботі, бойовим побратимам.",
      "Дякуємо Воїну за звитягу, мужність та відданість державі.",
      "Указом Президента України № 317/2023 «за особисту мужність, виявлену у захисті державного суверенітету та територіальної цілісності України, самовіддане виконання військового обов’язку» Олександра Литвака нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 16,
    name: "Сергій Віталійович МАЛОФЄЄВ",
    date: "28.09.1972 - 09.05.2022",
    photo: photo16,
    position: "навідник мотопіхотного батальйону ",
    texts: [
      "Захищаючи Україну від російських окупантів, загинув житель Вільногірська СЕРГІЙ  ВІТАЛІЙОВИЧ  МАЛОФЄЄВ.",
      "Народився Сергій Віталійович 28 вересня 1972 року у місті Сєвєрськ Томської області Росії. Служив у повітряно-десантних військах в Чечні. ",
      "Мав спеціальність водія, дуже любив техніку.",
      "Знайшов свою долю у нашому місті. Займався підприємницькою діяльністю, декілька років відпрацював у ТОВ Вільногірське скло. Працьовитий, скромний і дуже відповідальний.",
      "29 березня 2022 року за призовом по мобілізації Кам'янського РТЦК та СП Дніпропетровської област і пішов на військову службу. На фронті був навідником мотопіхотного батальйону однієї з військових частин.",
      "Загинув під м.Ізюм Харківської області 09 травня 2022 року, мужньо і  стійко захищаючи суверенітет і територіальну цілісність України.",
      "У Сергія Малофєєва залишилася дружина Світлана Михайлівна, три доньки, двоє онуків.",
      "Вся громада висловлює родині, друзям та близьким Сергія Малофєєва щирі співчуття. Низький уклін його безсмертному подвигу.",
      "Низький уклін нашому земляку за стійкість і звитягу, проявлені у боях за нашу державу. ",
      "Вічна слава Герою!",
      "Слава його безсмертному подвигу!",
      "Указом Президента України № 429/2022 «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Сергія Малофєєва нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 17,
    name: "Олександр Борисович МАРЦЕНЮК",
    date: "27.12.1973 - 21.08.2022",
    photo: photo17,
    position: "солдат у кулеметному взводі стрілецького батальйону ",
    texts: [
      "Сумний, трагічний час переживає Україна. Свої мчорним крилом війна вкотре торкнулась і нашої громади: у боях під Бахмутом на Донеччині загинув житель міста Вільногірськ Олександр Борисович МАРЦЕНЮК. Гірка і болюча втрата...",
      "Олександр МАРЦЕНЮК народився 27 грудня 1973 року в Конотопі Шепетівського району Хмельницької області. Закінчив місцеву школу із золотою медаллю. Армійську строкову службу проходив у Львові. Був гарним   майстром по дереву, на замовлення якісно виконував столярні роботи.  ",
      "Зустрів кохання свого життя - Людмилу. Разом створили дружну і міцну сім'ю, народили трьох донечок. Свою долю пов'язали із нашим містом. Останнім часом Олександр працював на Вільногірському ГМК стропальником.",
      "М'який за характером, добродушний, чуйний, Олександр Марценюк завжди мав дружні робочі стосунки у рідному трудовому колективі, був гарним і порядним сім'янином.",
      "Широкомасштабна російська агресія внесла свої корективи у життя багатьох українців. 13 липня першим відділом Кам'янського РТЦК та СП Олександра Борисовича Марценюка було призвано на військову службу по мобілізації. Воював солдат у кулеметному взводі стрілецького батальйону однієї з військових частин ЗСУ. Захищав рідну країну від підступного ворога. 21 серпня Олександр загинув у бою під населеним пунктом Бахмут. ",
      "Невимовний біль у серцях багатьох українців, коли в громади надходять звістки про загибель земляків. Найважче родинам Героїв. Вшановуючи громадянський і військовий подвиг Олександра Борисовича Марценюка, ми висловлюємо глибокі і щирі співчуття його родині: дружині, донькам, матері, сестрам, а також всім його близьким, друзям, колегам по роботі.",
      "Слава безсмертному подвигу  Олександра Борисовича Марценюка! ",
      "Указом Президента України № 799/2022 «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Олександра Марценюка нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [marcen1, marcen2],
  },
  {
    id: 50,
    name: "Ігор Валерійович МАСЛІКОВ",
    date: "17.12.1972 - 28.11.2023",
    photo: photo50,
    position: "гранатометник",
    texts: [
      "Останній його погляд був спрямований на маму... Він так боявся її засмутити. Ніколи не розповідав про війну, про свій біль.",
      "Беріг серце найдорожчої людини.",
      "Вільногірець Ігор Валерійович МАСЛІКОВ - ще одна наша страшна втрата на цій війні, ще одне життя.",
      "Народився Ігор у місті Вільногірськ 17 грудня 1972 року. Навчався у середній школі №5.",
      "Професію автослюсаря з ремонту БілАЗів здобув у нашому професійно-технічному училищі.",
      "Армійську підготовку проходив у понтонних військах у містах Нікополь та Дніпродзержинськ (зараз Кам'янське).",
      "Після демобілізації розпочав трудову діяльність.",
      "За спеціальністю працював у цеху гірничотехногічного транспорту Вільногірського ГМК, потім - у ТОВ `Вільногірське скло`.",
      "Врівноважений, гарно вихований, небагатослівний, він завжди приходив на допомогу друзям.",
      "25 квітня 2023 року по мобілізації з гідністю і готовністю пішов захищати рідну країну від російської навали.",
      "Солдат Національної Гвардії України Ігор МАСЛІКОВ брав участь у боях на Запорізькому напрямку, в районі міста Бахмут.",
      "Служив гранатометником 3-го батальйону оперативного призначення НГУ.",
      "Про проблеми зі здоров'ям, які виникли в ході служби, рідні навіть не знали.",
      "Після лікування в госпіталях Ігор знову повертався у військову частину, допоки нестерпний біль не зупинив його.",
      "28 листопада 2023 року перестало битися його хоробре серце.",
      "Хвороба виявилася невиліковною - онкологія. Помер наш земляк у госпіталі на руках своєї матусі, Євгенії Іванівни.",
      "Про те, що її син в госпіталі, вона дізналась від його побратимів і відразу ж поїхала, сподіваючись врятувати, пригорнути, підтримати Ігоря...",
      "Низький уклін мамі Героя, щирі співчуття доньці та п'ятьом онукам нашого захисника, його колегам, товаришам, друзям.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 18,
    name: "Віталій Юрійович МЕНЬШИКОВ",
    date: "19.08.1966 - 14.08.2022",
    photo: photo18,
    position: "інспектор прикордонної служби 2 категорії – гранатометник ",
    texts: [
      "Кожна людська втрата на цій війні – невимовний біль для всієї нашої країни. У Вільногірську міську територіальну громаду знову прийшла страшна звістка: 14 серпня 2022 року у боях під Авдіївкою отримав поранення, несумісне з життям, житель нашого міста Віталій Юрійович МЕНЬШИКОВ. ",
      "Народився Віталій Юрійович 19 серпня 1966 року у місті Марганець на Дніпропетровщині. Навчався у Вільногірській середній школі №4, потім у місцевому професійно-технічному училищі. Здобув професію екскаваторника. За спеціальністю і працював на Вільногірському гірничо-металургійному комбінаті. Мав певний авторитет серед колег по роботі, був дружнім, доброзичливим. Цікавився охотою, технікою, не пив, не палив. Був взірцем для багатьох людей зі свого оточення.",
      "Найціннішим у житті для нього була родина: дружина, двоє дітей, шестеро онуків, батьки. Останню, новонароджену онучку Віталію, на жаль, так і не довелося потримати на руках.",
      "Наприкінці лютого 2022 року почалося широкомасштабне вторгнення російських військ на територію України, а 13 березня нашого земляка було призвано на військову службу по мобілізації. ",
      "Служив молодший сержант Віталій Юрійович Меньшиков інспектором прикордонної служби 2 категорії – гранатометником одного з відділів прикордонної служби прикордонної комендатури швидкого реагування. У боях за Україну виявив стійкість і мужність як справжній Герой, гідний громадянин своєї держави.  ",
      "Висловлюємо глибокі співчуття родині, близьким, друзям, колегам воїна і низько вклоняємося світлому подвигу земляка. Це непоправна втрата для всіх нас, для всієї громади. ",
      "Вічна пам’ять захисникові Віталію Меньшикову.",
      "Указом Президента України № 672/2022 «за особисту мужність і самовідданість, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі, сумлінне та бездоганне виконання службового обов’язку» Віталія Меньшикова нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    Link: "https://vilnogirsk-rada.gov.ua/novyny-ta-oholoshennya/novyny/zhyty-i-pamyataty/",
    LinkText: "...Другі роковини загибелі",
    sliderImg: [menshikov1, menshikov2],
  },
  {
    id: 19,
    name: "Сергій Петрович НОСАЧ",
    date: "27.04.1969 - 10.09.2022",
    photo: photo19,
    position: "командир відділення 97 піхотного батальйону",
    texts: [
      "Перемога дається непросто. Вона дається потом та кров’ю наших військових, їхнім життям.  ",
      "У нашій громаді знову скорбота: 10 вересня 2022 року внаслідок несумісного із життям поранення на полі бою загинув вільногірець Сергій Петрович НОСАЧ.",
      "Народився Сергій Петрович 27 квітня 1969 року у нашому місті. Після закінчення середньої школи №4 навчався у Вільногірському технікумі. ",
      "Строкову службу в армії проходив на території Німеччини. За характером був спокійним, виваженим, захоплювався радіосправою. Працював гідромоніторщиком на Вільногірському ГМК, оператором склоформуючих машин у ТОВ «Вільногірське скло». ",
      "Сергій Носач був справжнім патріотом України. Захищаючи територіальну цілісність та суверенітет України, брав участь в АТО. Коли розгорнулася активна фаза війни з Росією, 24 лютого 2022 року його призвано Кам’янським РТЦК та СП на військову службу. Був командиром відділення 97 піхотного батальйону однієї з наших військових частин.",
      "Молодший сержант Сергій Петрович Носач загинув як Герой під час мінометного обстрілу з боку ворога поблизу смт Високопілля Бериславського району Херсонської області.Ціною життя таких воїнів, як наш земляк, і Високопілля, і десятки інших населених пунктів нарешті звільнені від ворога. ",
      "Ми пишаємося подвигом Сергія Петровича Носача. Висловлюємо щирі співчуття брату та племінницям воїна, всім його друзям і колегам по роботі. ",
      "Вічна пам’ять воїну-захиснику, справжньому патріоту України! ",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [nosach1, nosach2],
  },
  {
    id: 20,
    name: "Володимир Васильович ОСТРОУХ",
    date: "02.05.1965 - 22.07.2022",
    photo: photo20,
    position: "водій",
    texts: [
      "Війна... Жорстока і підступна, вона щодня забирає життя наших співвітчизників.",
      "22 липня 2022 року від травм, отриманих внаслідок ракетного удару по місцю розташування військової частини, загинув вільногірець, солдат ЗСУ Володимир Васильович  ОСТРОУХ.",
      "Народився наш земляк 02 травня 1965 року у селі Жовтоолександрівка П'ятихатського району Дніпропетровської області. ",
      "Навчався у Вільногірській середній школі 3, потім - у Верхньодніпровському професійно-технічному училищі. ",
      "Все трудове життя Володимир Васильович працював водієм, багато років праці віддав ГМК.",
      "Весела, оптимістична, творчалюдина. Його добре знали у місті як гарного колегу, чудового сім'янина і батька. Поціновувачі художньої самодіяльності знали Володимира Остроуха як трубача і співака оркестру духових інструментів Палацу культури та спорту Металург.",
      "З перших днів широкомасштабного вторгнення російських військ в Україну Володимира Остроуха було призвано до лав Збройних Сил України. Він і на фронті був водієм. ",
      "Загинув наш захисник в районі м.Апостолово.",
      "Вся громада сумує і висловлює щирі співчуття родині Володимира Остроуха: дружині Наталії, синові Андрію, матері, братові, а також всім друзям і колегам по роботі.",
      "Низький уклін солдатові за мужність і звитягу, проявлені у боях.",
      "Указом Президента України № 614/2022 «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Володимира Остроуха нагороджено медаллю «За військову службу Україні» (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [ostrouh3, ostrouh2, ostrouh1],
  },
  {
    id: 21,
    name: "Олександр Олександрович ПАНЧЕНКО",
    date: "31.08.1993 - 26.03.2023",
    photo: photo21,
    position: "солдат",
    texts: [
      "Виконуючи бойове завдання за призначенням в ході ведення бойових дій поблизу населеного пункту Водяне Донецької області, загинув вільногірець Олександр Олександрович ПАНЧЕНКО. ",
      "Олександр народився у місті Вільногірськ 31 серпня 1993 року. Навчався у ЗОШ №2 і ЗОШ №1, здобув професію електрогазозварника у Західно-Дніпровському центрі професійно-технічної освіти. ",
      "Строкову службу проходив у прикордонних військах на Закарпатті.  ",
      "Працював у ТОВ «Вільногірське скло», у філії «Вільногірський гірничо-металургійний комбінат» АТ «ОГХК», у ТОВ «Вільногірський завод залізобетонних виробів». ",
      "Знайомі та рідні характеризують його як добру, щедру людину, готову завжди підтримати інших, надати посильну допомогу, поділитися останнім.",
      "Коли росія розпочала широкомасштабну військову агресію проти нашої держави, Олександр Олександрович вже 02 березня 2022 року пішов добровольцем по мобілізації на передову. Він, як гідний громадянин України, не міг залишитись осторонь подій, прийняв рішення віддати свої уміння, досвід і сили на спільну справу тисяч українців – захист і визволення рідного народу, рідної землі. ",
      "Служив Олександр у стрілецькому батальйоні однієї з військових частин Збройних Сил України. Довелось на війні бути і кулеметником, і водієм-радіотелефоністом.  ",
      "26 березня 2023 року, виконуючи бойове завдання, солдат Олександр ПАНЧЕНКО загинув смертю хоробрих, до останньої хвилини вірнийсвоєму військовому і громадянському обов’язку.",
      "У полеглого залишились батьки, сестра і син. Від всієї громади висловлюємо родині, всім близьким, знайомим, колегам по роботі, бойовим побратимам Олександра щирі співчуття. ",
      "Батькам – низький уклін за гідне виховання сина. ",
      "Олександрові Олександровичу – вдячність за стійкість, мужність і звитягу, за всі зусилля, покладені на вівтар перемоги України у цій визвольній боротьбі.",
      "Вічна слава Герою! Світла пам’ять! Він завжди залишиться в наших серцях.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 22,
    name: "Андрій Олександрович ПАРФЕНЮК",
    date: "07.04.1987 - 24.01.2023",
    photo: photo22,
    position: "стрілець",
    texts: [
      "Соледар, Бахмут… Назви цих та багатьох інших населених пунктів Донеччини сьогодні у всіх новинах, у всіх на вустах, адже саме там точаться найзапекліші бої з рашистами. І саме там 24 січня 2023 року загинув наш земляк – Андрій Олександрович ПАРФЕНЮК…",
      "Андрій народився у місті Баку 07 квітня 1987 року. Політична ситуація в Азербайджані наприкінці 80-х років минулого століття була дуже напруженою. Родина Парфенюків, тікаючи від військових дій, із 3-річним Андрієм переїхала в наше місто. Тут Андрій вивчився у середній школі №3, з юних років працював у ТОВ «Вільногірське скло». Колеги по роботі та друзі згадують його як веселого, чуйного хлопця, компанійського і завжди готового підтримати ближнього. ",
      "У лютому 2022 року розпочалась широкомасштабна агресія росії проти України. 28 липня за призовом по мобілізації Кам'янського РТЦК та СП Дніпропетровської області Андрій Олександрович ПАРФЕНЮК пішов боронити державу від загарбників. На фронті був старшим стрільцем в одному зі стрілецьких батальйонів ЗСУ. ",
      "Загинув солдат в результаті поранення, несумісного з життям, під час ведення бойових дій поблизу населеного пункту Підгородне Донецької області. ",
      "Вся громада висловлює щирі співчуття його мамі та маленькій донечці, всім його близьким, друзям, колегам та побратимам. Смерті наших хлопців і дівчат, які могли і хотіли мирно жити, працювати, навчатись, народжувати і виховувати діточок, – це наш вічний біль. І немає прощення ворогу. ",
      "Низький уклін і глибока вдячність воїну за стійкість і звитягу, проявлені в боях за Україну, за кожного з нас.  ",
      "Вічна пам'ять солдатові і слава його безсмертному подвигу!",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 23,
    name: "Олександр Володимирович ПЄСКОВ",
    date: "30.09.2000 - 28.10.2022",
    photo: photo23,
    position:
      "перший номер обслуги мінометного взводу І мінометної батареї ІІ механізованого батальйону ",
    texts: [
      "28 жовтня 2022 року під час виконання бойових завдань обірвалося життя солдата ЗСУ, вільногірця Олександра Володимировича ПЄСКОВА.",
      "Народився Олександр 30 вересня 2000 року. Був найменшою дитиною у багатодітній сім’ї. Вдома завжди допомагав мамі по господарству. ",
      "Навчався у загальноосвітній школі № 2 м.Вільногірськ. Після 9 класу здобував освіту у ДПТЗ «Західно-Дніпровський центр професійно-технічної освіти» за спеціальністю «Машиніст екскаватора. Електрослюсар (слюсар) черговий та з ремонту устаткування. Електрогазозварювальник».",
      "І в школі, і в училищі характеризувався як дуже відповідальний, спокійний, старанний, працьовитий учень. Олександр ніколи не любив конфліктних ситуацій, відзначався гарними товариськими якостями, добропорядністю і людяністю. Олександр брав активну участь у культурному та спортивному житті ЗДЦПТО. Навіть по закінченню навчання він неодноразово бував у навчальному закладі, не втрачав зв’язок із учнівсько-педагогічним колективом. ",
      "12 листопада 2021 року був призваний першим відділом у м.ВерхньодніпровськКам’янського РТЦК та СП Дніпропетровської області на військову службу за контрактом.",
      "Солдат Олександр Пєсков служив першим номером обслуги мінометного взводу І мінометної батареї ІІ механізованого батальйону однієї з військових частин ЗСУ.  ",
      "Виявивши стійкість та мужність, героїчно загинув у бою біля населеного пункту Андріївка Сватівського району Луганської області. Йому було всього 22 роки…",
      "Щирі співчуття матері Олександра Галині Адамівні, всім рідним, близьким, друзям, побратимам Героя. Пам’ять про нього залишиться назавжди у наших серцях.",
      "Слава нашим визволителям! Герої не вмирають!",
      "Указом Президента України № 10/2023 Про відзначення державними нагородами України «за особисту мужність і самовідданість, виявлені у захисті державного суверенітету та територіальної цілісності України, сумлінне і бездоганне служіння Українському народові» Олександра Пєскова нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 24,
    name: "Володимир Валентинович ПЛУЖНИК",
    date: "12.11.1995 - 07.03.2022",
    photo: photo24,
    position: "старший солдат",
    texts: [
      "Володимир Плужник народився 12 листопада 1995 року в селі Дідове Верхньодніпровського району Дніпропетровської області. ",
      "Навчався в Андріївській неповній середній загальноосвітній школі Верхньодніпровської міської ради.",
      "Потім здобував професію електрогазозварника в Західно-Дніпровському центрі професійно-технічної освіти. ",
      "Строкову службу проходив у Нацгвардії в місті Одеса.",
      "Після демобілізації працював у ТОВ 'Вільногірське скло'.",
      "У серпні 2019 року за контрактом став до лав ЗСУ, щоб захищати територіальну цілісність і незалежність рідної держави. ",
      "Служив у 79-й окремій десантно-штурмовій бригаді ЗСУ.",
      "07 березня 2022 року старший солдат Володимир Плужник героїчно загинув у бою поблизу населеного пункту Щастя Донецької області.",
      "Похований 11 березня 2022р. в с.Терно-Лозуватка Вишнівської селищної громади Кам'янського району Дніпропетровської області за місцем проживання матері.",
      "Указом Президента України від 18.07.2023 року № 433/2023 за особисту мужність, виявлену в захисті державного суверенітету та територіальної цілісності України, самовіддане виконання військового обов’язку старшого солдата Володимира Валентиновича ПЛУЖНИКА нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [plug1, plug2, plug3],
  },
  {
    id: 67,
    name: "Олександр Миколайович ПОЙДА",
    date: "30.03.1964 - 25.10.2024",
    photo: photo67,
    position: "полковник Збройних Сил України",
    texts: [
      "28 жовтня 2024 року, у Києві пройшла церемонія прощання з нашим земляком, захисником України полковником Збройних Сил України Олександром Миколайовичем ПОЙДОЮ",
      "За свої 60 років життя Олександр Миколайович пройшов гідний і героїчний шлях, про який повинні знати і пам’ятати люди. ",
      "Народився Олександр 30 березня 1964 року у м. Вільногірськ Верхньодніпровського району Дніпропетровської області. ",
      "Навчався у Вільногірській середній школі №4, а потім – у місцевому ПТУ за спеціальністю «Машиніст екскаватора вугільної та гірничорудної промисловості».",
      "Після армійської служби розпочав свою трудову діяльність помічником машиніста екскаватора на місцевому ГМК.",
      "Потім працював у Верхньодніпровському ремонтно-будівельному спеціалізованому управлінні, на Вільногірському заводі електровакуумного скла.",
      "У Вільногірському гірничо-металургійному технікумі отримав спеціальність «Електрообладнання промислових підприємств та установ». ",
      "Працював інженером відділу збуту у ТОВ «Вільногірське скло», інженером відділу маркетингу ТОВ «Хімтек» у Вільногірську. ",
      "Олександр Миколайович завжди був активним і небайдужим громадянином своєї держави.",
      "Тому частину свого життя присвятив суспільно-політичній діяльності. Обирався депутатом Мишуринрізької сільської ради, працював помічником-консультантом у двох народних депутатів Верховної Ради України третього та четвертого скликання. У 2006 році був обраний депутатом Вільногірської міської ради V скликання.",
      "Працював на посаді секретаря ради. Олександр Миколайович завжди мав власну думку, виступав за незалежну і суверенну Україну, за її свободу і територіальну цілісність.",
      "Наш земляк без сумнівів і коливань долучився до подій Помаранчевої революції, Революції Гідності (2013 рік – входив у склад VIIIАфганської сотні), брав участь у спецопераціях, в АТО, займався волонтерською діяльністю. Про це свідчать його друзі і бойові побратими.",
      "З 2017 року очолював Подільську районну в місті Києві громадську організацію інвалідів війни, Збройних Сил України та учасників бойових дій.",
      "З 24 лютого 2022 року воював на бучанському напрямку, був важко поранений. Але це не зупинило його боротьбу.",
      "Після лікування і реабілітації він продовжив служити в територіальній обороні Києва. Має відомчі і державні нагороди.",
      "На жаль, 25 жовтня 2024 року після тривалої хвороби Олександр Миколайович ПОЙДА відійшов у вічність. До останньої хвилини життя він залишився вірним військовій присязі і громадянському обов’язку. Честь і слава землякові. ",
      "Міський голова Володимир ВАСИЛЕНКО від імені Вільногірської міської територіальної громади висловлює щирі співчуття мамі покійного Любові Самойлівні, братові Сергію Миколайовичу, дітям, онукам, друзям і колегам Олександра Миколайовича по роботі з приводу великої втрати.",
      "Його прах упокоїться на Берковецькому кладовищі м. Київ біля могили його бойової супутниці і дружини Лариси.Таким було бажання воїна. Вічна слава Герою і світла пам’ять!",
      
    ],
   
  },
  {
    id: 37,
    name: "Владислав Дмитрович ПРОСТЯКОВ",
    date: "16.10.1996 - 13.12.2018",
    photo: photo37,
    position: "рядовий",
    texts: [
      "16 грудня 2018 року Вільногірськ прощався з воїном-контрактником Збройних Сил України, що загинув, виконуючи свій священний обов’язок.",
      "Простяков Владислав Дмитрович. ",
      "Це ім’я відтепер навічно буде закарбовано у символічній книзі пам'яті нашого міста, як приклад самовідданого і жертовного служіння державі, проявленої мужності під час захисту територіальної цілісності України та інтересів українського народу.",
      "Його біографія лише розпочиналася, його юнацькі мрії, плани та задуми лише формувалися і починали здійснюватися. Було йому лише 22.",
      "Народився Владислав 16 жовтня 1996 р. у м.Вільногірськ. З дитинства цікавився навколишнім світом, був допитливим і спостережливим, захоплювався спортом, непогано грав у футбол.  ",
      "Всюди і завжди був компанійським, мав багато друзів. З повагою ставився до старших, вчителів, педагогів. Навчався спочатку у Вільногірській ЗОШ № 2, а після 9-го класу поступив у ПТУ м.Дніпро. На вибір професії кулінара у значній мірі вплинуло дитяче та юнацьке захоплення. Він обрав мирну професію, мріяв нести людям добро та радість. Але доля підготувала інші випробування.",
      "Новий і переломний період для Владислава розпочався 2016 року, коли його було призвано Верхньодніпровським РВК на строкову службу до лав Збройних Сил України. Після демобілізації він, як загартований певним життєвим досвідом чоловік, приймає тверде рішення продовжити службу за контрактом у складі 79-ої окремої десантно-штурмової бригади. За період несення служби сумлінно виконував свої обов’язки, за що й був нагороджений медаллю «За звитягу та вірність». ",
      "13 грудня 2018р. став останнім днем життя рядового Владислава Простякова. Під час масованого ворожого артилерійського обстрілу поблизу с.Гнутово (Приазовський напрямок) він отримав важке поранення. Побратими негайно доправили його у Маріупольський військовий шпиталь, сподіваючись врятувати.",
      "На жаль, поранення виявилося несумісним з життям.",
      "Сумна звістка охопила серця близьких та рідних. Усі, хто знав Владислава, спілкувався з ним, та й просто пересічні громадяни нашого міста подумки ставлять практично одні й ті ж запитання: «Чому?», «Навіщо?», «За що?».",
      "Пам'ять про Владислава Простякова завжди житиме в наших серцях. Указом Президента України № 47/2019 від 28 лютого 2019 року, за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, нагороджений орденом «За мужність» III ступеня (посмертно).",
      "Вічна слава Герою!",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },

  {
    id: 26,
    name: "Павло Миколайович ПОСТОЛАКА",
    date: "16.12.1984 - 17.07.2023",
    photo: photo26,
    position:
      "номер обслуги зенітного ракетно-артилерійського відділення роти вогневої підтримки  ",
    texts: [
      "Вільногірська громада у скорботі… 17 липня 2023 року неподалік від населеного пункту Макарівка Волноваського району Донецької області героїчно загинув наш земляк Павло Миколайович ПОСТОЛАКА. Він захищав кожного з нас, захищав нашу рідну землю.",
      "Народився Павло ПОСТОЛАКА 16 грудня 1984 року у місті Дніпродзержинськ (зараз – місто Кам’янське). Шкільні роки хлопчика проходили у Верхівцевській середній школі №1.",
      "Трудова діяльність Павла Миколайовича пов’язана з філією «Вільногірський гірничо-металургійний комбінат» АТ «ОГХК». Він працював на гірничому виробництві ГТВ помічником машиніста екскаватора. ",
      "Колеги по роботі, друзі назавжди запам’ятають його як гарного і відповідального працівника, чуйну, добру, порядну людину.",
      "Найбільшою цінністю для нашого земляка завжди була родина: дружина, син, батьки.",
      "Коли у лютому 2022 року росія розпочала проти нас широкомасштабну агресію, Павло став на захист України. 17 березня 2022 року його було призвано на військову службу по мобілізації. Як і тисячі наших співвітчизників, мирний працівник промислового підприємства став солдатом. Він служив номером обслуги зенітного ракетно-артилерійського відділення роти вогневої підтримки однієї з військових частин. ",
      "Загинув під час ворожого артобстрілу, з гідністю виконуючи свої військові обов’язки, захищаючи територіальну цілісність та суверенітет нашої держави.",
      "Щирі співчуття сім’ї нашого захисника: дружині Людмилі, синові Сергію. Низький уклін батькам Павла Ользі Володимирівні та Миколі Миколайовичу за сина, за його гідне виховання.",
      "Вічна світла пам’ять Воїну! Смерть ворогам! ",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
      "Указом Президента України № 371/2024 від 21.06.2024 за особисту мужність, виявлену у захисті державного суверенітету та територіальної цілісності України, самовіддане виконання військового обов’язку Павла ПОСТОЛАКУ нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
    ],
  },
  {
    id: 27,
    name: "Дмитро Ігорович РАКУТА",
    date: "20.03.1991 - 02.11.2022",
    photo: photo27,
    position: "солдат",
    texts: [
      "Гірка звістка знову надійшла у нашу громаду: у боях з російськими окупантами загинув уродженець Вільногірська Дмитро Ігорович РАКУТА. Ще один член нашої громади, ще один громадянин України віддав життя за її територіальну цілісність, за мирне небо, за нашу свободу.",
      "Дмитро народився у м.Вільногірську 1991 році. Навчався в загальноосвітній школі №5, потім у нашому технікумі, далі – у м.Кам’янське. Здобув економічну спеціальність. Повернувся в місто, працевлаштувався у філію «Вільногірський ГМК» АТ «ОГХК» помічником машиніста екскаватора на ГТВ. У вільний час захоплювався автосправою та музикою. ",
      "Наприкінці лютого 2022 року розпочалась широкомасштабна російська агресія. 14 травня Дмитра призвано на військову службу І-м відділом Кам’янського РТЦК та СП Дніпропетровської області. Служив у мотопіхотному відділенні мотопіхотного батальйону однієї з військових частин ЗСУ. У боях проявив себе як мужній і звитяжний воїн, як вірний син своєї країни.",
      "Дмитро Ігоревич РАКУТА героїчно загинув під час виконання бойового завдання 02 листопада 2022 року в районі населеного пункту Білогорівка Луганської області. До останнього дня, останньої хвилини свого життя воїн залишився вірним військовій присязі.",
      "Вільногірська громада висловлює щирі співчуття мамі Дмитра Людмилі Миколаївні, всій його родині, друзям, колегам по роботі, фронтовим побратимам. Кожна втрата – горе для всіх нас, невимовний біль.  ",
      "Вічна світла пам’ять Герою. Його ім’я навіки вписане не лише в історію міста, а й всієї країни. Низький уклін захисникові…",
      "Слава і шана Герою!",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 55,
    name: "Андрій Вікторович РЕВУТА",
    date: "02.04.1989 - 16.03.2024",
    photo: photo55,
    position: "начальник медичної служби",
    texts: [
      "Війна, клятий ворог забирає життя кращих синів України. Андрій Вікторович РЕВУТА. Навіки 34 роки... ",
      "Народився Андрій 02 квітня 1989 року у місті Вільногірську. Тут проходило його дитинство, навчання у загальноосвітній середній школі №4.",
      "Педагоги і однокласники пам'ятають Андрія як виховану, врівноважену людину, допитливого учня, гарного друга.",
      "У 2006 році, після отримання свідоцтва про середню освіту, вступив у Дніпропетровську медичну академію, вивчився на лікаря.",
      "Спеціалізувався на щелепно-лицьовій хірургії. В одній із лікарень Кривого Рогу проходив інтернатуру та післядипломну практику. Потім працював за фахом у Дніпрі.",
      "Розпочалась широкомасштабна російська агресія. У липні 2023 року Соборним РТЦК та СП міста Дніпро Андрія Вікторовича РЕВУТУ було мобілізовано до лав ЗСУ.",
      "Він пройшов відповідне навчання в Академії сухопутних військ України за системою НАТО.",
      "Лейтенант Андрій РЕВУТА займав посаду начальника медичної служби 5 окремої десантно-штурмової бригади Збройних Сил України.",
      "Щодня з новин ми отримуємо інформацію про жорстокі бої на Донеччині. Саме там проходив службу наш земляк, рятував життя співвітчизників, робив свій гідний внесок у загальнонаціональну боротьбу Українського народу проти рашистської навали.",
      "Саме там, на Донеччині, в районі населеного пункту Часів Яр Андрій отримав осколкові поранення, не сумісні з життям.",
      "Це сталося 16 березня 2024 року. Місцева влада, вся громада висловлює щирі, глибокі співчуття мамі воїна Тетяні Анатоліївні, батькові Віктору Васильовичу, сестрі Юлії, всім його рідним, друзям, колегам. Поховали земляка на місцевому кладовищі.",
    ],
  },
  {
    id: 6,
    name: "Віталій Іванович РОМАНОВСЬКИЙ ",
    date: "15.01.1977 - 21.03.2023",
    photo: photo6,
    position: "стрілець-розвідник відділення спеціальних дій",
    texts: [
      "Вільногірець Віталій Іванович РОМАНОВСЬКИЙ загинув під населеним пунктом Довгеньке Ізюмського району Харківської області. Сумом і болем пронизані серця багатьох з нас.",
      "Віталій народився у Вільногірську 15 січня 1977 року. Навчався у середній школі №5. За характером був веселим, добрим хлопчиком, завжди мав багато друзів.  ",
      "Після школи закінчив академію «ШАГ», а потім багато років свого життя віддав виробництву. Працював провідним інженером з комп’ютерних систем у ТОВ «Вільногірське скло», у ТОВ «Скляний Альянс». Був грамотним, висококваліфікованим спеціалістом з ІТ-технологій, відповідальним, дисциплінованим працівником і порядною людиною. ",
      "Віталій Іванович дуже любив свою родину. Багато часу приділяв дітям(їх у нього четверо рідних і один – прийомний), онукові Назарію. Опікувався їхнім навчанням, дозвіллям, вихованням – жив ними. ",
      "Захоплювався пошуковою роботою. Зокрема долучався до роботи громадської організації «Пошук-Дніпро», яка співпрацювала з обласним історичним музеєм з питань встановлення місць загибелі українців, військових поховань під час Другої світової війни, зокрема на Дніпровських висотах.",
      "У 2014 році увійшов у пошукову групу «Чорний тюльпан». Разом зі своїми однодумцями, ризикуючи життям, здійснював роботу з пошуку загиблих та зниклих безвісти в районі Іловайського котла, вивезення тіл.",
      "Коли у лютому 2022 року росія розпочала проти нас широкомасштабну війну, Віталій Іванович відразу ж записався в ряди територіальної оборони Дніпра, а вже 03 березня 2022 року добровольцем пішов на передову. ",
      "Солдат Віталій РОМАНОВСЬКИЙ з позивним «Висота» служив стрільцем-розвідником відділення спеціальних дій в загоні спецпризначення однієї з військових частин ЗСУ. До останнього подиху він мужньо і звитяжно виборював право своєї батьківщини, своєї нації на існування, на свободу, на цілісність і незалежність.  ",
      "21 березня 2023 року ворог забрав життя Воїна. Без батька залишилися діти, без дідуся - онук.Не дочекалися його повернення мама, брат та сестра. Від всієї громади висловлюємо глибокі співчуття родині, а також друзям, колегам, побратимам Віталія. Низький уклін його безсмертному подвигу. ",
      "Указом Президента України № 517/2023 Про відзначення державними нагородами України «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Віталія Романовського нагороджено медаллю “За військову службу Україні”",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [romanov1, romanov2, romanov3, romanov5, romanov7, romanov8],
  },
  {
    id: 47,
    name: "Іван Вікторович РЯЩЕНКО ",
    date: "24.03.1983 - 08.03.2018",
    photo: photo47,
    position: "Молодший сержант ЗСУ",
    texts: [
      "Іван Вікторович Рященко народився 1983 року у місті Вільногірськ Дніпропетровської області. У віці 2,5 років втратив матір, разом з молодшим братом виховувався бабусею. ",
      "З 1995 року мешкав в смт Вишневе П'ятихатського району Дніпропетровської області. До 9 класу навчався у Вишнівській середній школі, потім вступив до Саксаганського професійно-технічного училища №77. Отримав спеціальності тракториста та водія. ",
      "Після навчання одразу пішов працювати, щоб допомагати бабусі. Працював на Вільногірському гірничо-металургійному комбінаті, на підприємстві «Вільногірське скло». ",
      "Захоплювався технікою, історією, серйозно займався вивченням культури скіфів. ",
      "Зі своєю дружиною познайомився давно. Спочатку жили у цивільному шлюбі, а 24 лютого 2017 року офіційно зареєстрували відносини. Жили у будинку, що дістався йому та братові у спадщину від бабусі. ",
      "З 09 червня 2015 по 14 липня 2016 проходив службу за мобілізацією у 28-й бригаді на посадах радіотелефоніста та водія-заряджаючого реактивного артилерійського дивізіону. Наприкінці служби був переведений на посаду стрільця-санітара 1-го механізованого батальйону. ",
      "15 вересня 2016 року підписав контракт з 54-ю бригадою. Молодший сержант, командир БМП — командир 1-го відділення 3-го взводу 6-ї роти 2-го механізованого батальйону. Воював на Світлодарській дузі, у Троїцькому, промзоні Авдіївки.",
      "05 серпня 2017 року майже вся рота була відряджена до Авдіївки на бойову службу. Тут протягом 4 днів Іван вистежував ворожого кулеметника й ліквідував його з гранатомета. 07 лютого 2018 зазнав контузії. Після лікування на початку березня повернувся на передову.",
      "08 березня 2018 року загинув увечері під час бойового чергування поблизу смт Луганське на Світлодарській дузі від кульового поранення у скроню внаслідок кулеметного обстрілу.",
      "11 березня 2018 похований у Вишневому.",
      "У Івана Рященка залишилися батько, дружина, троє синів.",
      "Указом Президента України № 189/2018 від 27 червня 2018 року «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, зразкового виконання військового обов'язку» нагороджений орденом «За мужність» III ступеня.",
    ],
  },
  {
    id: 60,
    name: "Олег Миколайович СІНГАЇВСЬКИЙ ",
    date: "14.12.1973 - 22.01.2023",
    photo: photo60,
    position: "Механік-водій понтонних танків",
    texts: [
      "Донеччина… Кожен сантиметр землі її окроплений кров’ю наших воїнів. Неймовірна мужність керує нашими захисниками у прагненні очистити міста і села від ворога, повернути тимчасово захоплені ним території.",
      "За інформацією, що надійшла від військових, 22 січня 2023 року під час ведення бойових дій в Донецькій області отримав поранення, несумісні з життям, мешканець Вільногірська Олег Миколайович СІНГАЇВСЬКИЙ.",
      "Олег народився 14 грудня 1973 року в місті Дніпродзержинськ (нині – Кам’янське). Після середньої школи навчався в професійно-технічному училищі, набув спеціальність електрозварювальника.",
      "Строкову службу ніс в інженерних (понтонно-мостових) військах в місті Миколаїв. Був механіком-водієм понтонних танків.",
      "Після демобілізації створив міцну родину, разом із дружиною Світланою виховував двох донечок. Працював на Вільногірському ГМК в ремонтно-механічному управлінні. Завжди мав багато друзів, був порядною доброю людиною і гарним спеціалістом.",
      "Коли в лютому 2022 року розпочалась широкомасштабна російська агресія, Олег Миколайович вже 02 березня був призваний на службу по мобілізації першим відділом Кам’янського РТЦК та СП Дніпропетровської області. До військового обов’язку поставився з гідністю і відповідальністю. На фронт він взяв із собою навіть власний автомобіль.",
      "Служив солдат Олег СІНГАЇВСЬКИЙ навідником кулеметного взводу стрілецького батальйону однієї з військових частин ЗСУ. Воював спочатку на Херсонщині, а потім на Бахмутському напрямку. Загинув воїн 22 січня 2023 року поблизу населеного пункту Благодатне Донецької області.",
      "Ми знаємо із новин, від учасників і свідків військових подій, наскільки складні бувають ситуації на передовій. На жаль, військові так і не змогли доставити тіло нашого земляка на батьківщину, але допомогли органам юстиції підтвердити факт загибелі нашого земляка.",
      "Висловлюємо глибокі співчуття родині Олега Миколайовича, всім його близьким, друзям, колегам, побратимам. Низький уклін Герою за звитягу і вічна слава!",
    ],
  },
  {
    id: 28,
    name: "Андрій Миколайович СКАЛА",
    date: "22.11.1974 - 23.06.2022",
    photo: photo28,
    position: "солдат",
    texts: [
      "Наша громада понесла ще одну гірку втрату в цій війні: 23 червня 2022 року у бою поблизу села Єгорівка Донецької області внаслідок артилерійського обстрілу зі сторони збройних сил РФ загинув наш земляк АНДРІЙ МИКОЛАЙОВИЧ СКАЛА. Він виконував бойове завдання, виявивши неперевершену стійкість і мужність.",
      "Народився Андрій Скала 22 листопада 1974 року у м.Вільногірськ. Навчався у ЗОШ №3, а потім здобув професію водія в місцевому професійно-технічному училищі, 2 роки відслужив у лавах армії під Одесою. ",
      "Все життя залишався вірним професії. Працював у ТОВ «Мотронівський гірничо-збагачувальний комбінат» водієм БєлАЗу. 2020 року отримав почесне звання «Ветеран праці». Завжди користувався певним авторитетом серед колег по роботі, мав багато друзів, був гарним сім’янином і справжнім патріотом України. ",
      "У лютому 2022 року почалась активна фаза російського вторгнення в Україну. Вже 02 березня Андрій Миколайович був призваний за мобілізацією в механізований батальйон однієї з військових частин ЗСУ. Брав участь у боях за Волноваху, Краснопілля, Володимирівку та інші міста і села нашої країни. ",
      "На жаль, ворожий снаряд обірвав життя прекрасної людини. У воїна залишились дружина і дві доньки. Висловлюємо співчуття родині Андрія Миколайовича, його рідним і близьким, колегам і друзям.  ",
      "Пишаємося героїчним життям нашого земляка, його безсмертним подвигом!",
      "Слава Герою! Слава Україні і смерть ворогам!",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [skala1, skala2, skala3, skala4, skala5, skala6],
  },
  {
    id: 56,
    name: "Юрій Миколайович СЛЮСАР",
    date: "19.07.1976 - 10.04.2024",
    photo: photo56,
    position: "стрілець-снайпер",
    texts: [
      "Сини і доньки нашої країни…",
      "Мужні, стійкі і сильні. Наші серця плачуть від болю і смутку, коли втрачаємо когось із вас. Коли ворог вирішує вашу долю і сиротить цілі родини. ",
      "Із однієї з військових частин Міністерства оборони України в громаду надійшло сповіщення про втрату ще одного нашого земляка, жителя міста Вільногірська – Юрія Миколайовича СЛЮСАРА.",
      "Юрій народився 19 липня 1976 року на Миколаївщині.Там закінчив середню школу, вивчився на водія. В Охтирці Сумської області пройшов строкову службу. Отримав військове звання «старший сержант». ",
      "2001 року вже з молодою родиною Юрій приїхав у Вільногірськ. Всі роки працював у ЦГТТ на Вільногірському ГМК. Сім’я виховувала двох діточок, Данила та Вікторію, будувала мирні плани, як і кожний з нас. Але війна зруйнувала спокійний родинний затишок.",
      "28 березня 2022 року Кам’янським РТЦК та СП у Дніпропетровській області Юрія Миколайовича призвано на військову службу по мобілізації.",
      "Саме з філії «ВГМК» АТ «ОГХК» він і пішов захищати батьківщину від ворога. Він служив стрільцем-снайпером 1 взводу 2 мотопіхотної роти одного з мотопіхотних батальйонів.",
      "Два роки старший сержант Юрій Миколайович СЛЮСАР віддано і чесно боронив нашу Україну, наш народ від російської навали. 10 квітня 2024 року в результаті здійснення противником скиду вибухового пристрою з БпЛА по позиції підрозділу він загинув.",
      "Загинув, виконуючи свій військовий і громадянський обов’язок. Це сталося поблизу міста Вугледар Волноваського району Донецької області.",
      "Вся громада висловлює щирі слова співчуття родині нашого воїна: дружині, дітям, батькам, всім близьким, друзям, колегам по роботі.",
      "Низький уклін Юрію, наша глибока вдячність за подвиг в ім’я миру і вічний наш спомин.",
      "Поховали Юрія Миколайовича СЛЮСАРА на Алеї Слави міського кладовища.",
    ],
  },
  {
    id: 29,
    name: "Сергій Юрійович СОКОЛ",
    date: "16.09.1981 - 16.11.2022",
    photo: photo29,
    position:
      "номер обслуги 3 гранатометного відділення взводу вогневої підтримки 1 аеромобільної роти 1 аеромобільного батальйону",
    texts: [
      "Війна… Лише за цей рік широкомасштабної російської агресії за інформацією, що надійшла від військових, на полях боїв наша міська територіальна громада втратила 20 земляків. Серед них – Сергій Юрійович СОКОЛ, житель міста Вільногірськ.",
      "Сергій СОКОЛ народився 16 вересня 1981 року у місті Маріуполь (на той час Жданов) Донецької області. У Вільногірську Сергій пішов навчатися в середню школу №4. З юних років відзначався добрим характером, чуйністю, скромністю. ",
      "Після отримання базової середньої освіти проходив армійську службу в місті Євпаторія.  ",
      "У лютому 2022 року, коли росія розпочала активні воєнні дії проти України, Сергій Юрійович СОКОЛ працював черговим слюсарем у ТОВ «Скляний Альянс». ",
      "15 жовтня був призваний першим відділом у місті Верхньодніпровськ Кам’янського РТЦК та СП Дніпропетровської області по мобілізації.",
      "Служив номером обслуги 3 гранатометного відділення взводу вогневої підтримки 1 аеромобільної роти 1 аеромобільного батальйону. ",
      "21 жовтня під час виконання бойового завдання біля населеного пункту Білогорівка Сєвєродонецького району Луганської області наш захисник отримав вогнепальне поранення, від якого і загинув 16 листопада.",
      "Звичайний громадянин України, як тисячі інших наших співвітчизників, у момент реальної загрози для всього нашого народу став на захист територіальної цілісності країни. Він віддав власне життя, щоб стримати збройну агресію росії, дати відсіч ворогові. Це подвиг в ім’я України, в ім’я людства.",
      "У Сергія Юрійовича СОКОЛА залишились мати, батько, сестра. Висловлюємо щирі співчуття родині, всім близьким, друзям загиблого.",
      "Указом Президента України № 315/2023 Про відзначення державними нагородами України «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Сергія Сокола нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 1,
    name: "Сергій Анатолійович СОКОЛЕНКО",
    date: "30.05.1969 - 04.01.2023",
    photo: photo1,
    position: "молодший інспектор І категорії – помічник гранатометника",
    texts: [
      "04 січня 2023 року внаслідок гострої серцево-судинної недостатності та гострого панкреатиту помер наш захисник, житель Вільногірська Сергій Анатолійович СОКОЛЕНКО. Віддав життя, захищаючи Україну від російської навали.",
      "Народився Сергій 30 травня 1969 року у Вільногірську. Навчався у середній школі №3. Коли Сергію було 11 років, на його руках померла мама, невдовзі пішов з життя і батько. Виховувала хлопчика його рідна тітка Майя Олександрівна Яковлева. Виховувала з любов’ю, як рідного.",
      "Сергій змалку мріяв стати пілотом, навіть вступив в авіаційне училище, але доля склалася по-іншому. У 1987-1988 роках проходив армійську службу в м. Капустин Яр Архангельської області у ракетних військах.",
      "Потім працював на заводі електровакуумного скла. Був майстром на всі руки. Багато хто з вільногірців вдячні йому за відмінно виконані ремонтні роботи. У приватному порядку він і займався цією справою, був дуже безкорисливим і завжди приходив людям на допомогу.",
      "Великою втратою для Сергія Анатолійовича була передчасна смерть єдиного сина Дмитра. На все життя вона залишилася для нього відкритою раною.",
      "Свою батьківську любов він сповна віддав прийомній доньці Катрусі, виростивши її практично змалку як рідну.",
      "Сергій завжди був не лише гарним чоловіком і батьком, а й справжнім патріотом. З початком широкомасштабної агресії росії проти України він прийняв для себе добровільне рішення йти боронити рідну державу. З 13 березня Сергій Анатолійович СОКОЛЕНКО служив молодшим інспектором І категорії – помічником гранатометника в одному із загонів Державної прикордонної служби України. Помер старший солдат СОКОЛЕНКО у смт Краснопілля Сумської області.",
      "Низький уклін і вдячність Герою за відданість і вірність державі, за мужність і стійкість.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 54,
    name: "Валерій Анатолійович СОЛДАТЕНКО",
    date: "17.06.1977 - 20.02.2024",
    photo: photo54,
    position: "майор",
    texts: [
      "За щасливу долю мільйонів людей, за існування і процвітання нашої незалежної держави віддають життя кращі сини і доньки Українського народу.",
      "20 лютого 2024 року під час виконання бойового завдання по захисту України від московитів героїчно загинув наш земляк - майор Валерій Анатолійович СОЛДАТЕНКО.",
      "Народився Валерій у місті Вільногірськ 17 червня 1977 року.",
      "1995 року закінчив Вільногірську середню школу №3 і вступив на перший курс військової кафедри Дніпропетровського державного університету залізничного транспорту.",
      "З 2000 року, після отримання диплому, присвятив себе військовій службі.",
      "Брав участь в АТО. Залишився вірним присязі і 2022 року, коли російські загарбники розпочали широкомасштабну агресію проти України.",
      "Після проходження спеціальної підготовки приступив до служби у Луганському прикордонному загоні імені Героя України полковника Євгена Пікуса.",
      "У рамках кампанії 'Гвардія наступу' зараз цей загін став бригадою 'Помста' у загальній структурі Державної прикордонної служби України. 'Відроджені перемогти!' - з цим гаслом прикордонники бригади мужньо стоять на захисті наших територій, йдуть на найнебезпечніші операції.",
      "Офіцер Валерій Анатолійович СОЛДАТЕНКО з гідністю і відданістю рідній державі виконував свій військовий і громадянський обов'язок. ",
      "Загинув під час виконання одного з бойових завдань в районі населеного пункту Іванівське Донецької області.",
      "Вся наша громада висловлює глибокі та щирі співчуття мамі Валерія - Ларисі Анатоліївні, його рідній тітці Валентині Анатоліївні Куземі, всім рідним, друзям та побратимам.",
      "Поховають Воїна на міському кладовищі.",
    ],
  },
  {
    id: 12,
    name: "Костянтин Сергійович СРІБНИЙ",
    date: "09.12.1991 - 13.04.2022",
    photo: photo12,
    position: "старший солдат ",
    texts: [
      "Вільногірська міська територіальна громада втратила ще одного свого воїна. Під час виконання бойового завдання, виявивши стійкість і мужність, загинув старший стрілець механізованого батальйону однієї з військових частин, старший солдат Костянтин Сергійович Срібний.",
      "Народився Костянтин Срібний 09 грудня 1991 року у місті Дніпро. Навчався у середній загальноосвітній школі №88. У будівельному професійно-технічному ліцеї здобув спеціальності електрогазозварювальника та водія автотранспортних засобів. Завжди захоплювався технікою. А ще мріяв стати військовим.  ",
      "Декілька років проживав з родиною у с.Дідове Дмитрівського старостинського округу Вільногірської територіальної громади. Працював у воєнізованій охороні ТОВ «Мотронівський гірничозбагачувальний комбінат».  ",
      "Коли розпочалась антитерористична операція, без роздумів пішов воювати за Україну, адже вважав за свій обов’язок стати на захист цілісності та незалежності рідної держави. Загалом був на передовій 3,5 роки.",
      "24 лютого 2022 року, коли розпочалась активна фаза війни, був призваний Кам’янським РТЦК та СП на військову службу по мобілізації. 13 квітня Костянтин Сергійович Срібний загинув під населеним пунктом Барвінкове Харьківської області.",
      "Спокійний, товариський, сповнений оптимізму і впевнений у собі – саме таким запам’ятається Костянтин побратимам, друзям та колегам по роботі",
      "Висловлюємо щирі співчуття матері, жінці та доньці загиблого Героя. ",
      "Указом Президента України № 329/2022 Про відзначення державними нагородами України «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Костянтина Срібного нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [sribn1, sribn2],
  },
  {
    id: 40,
    name: "Олег Степанович СТАСЮК",
    date: "07.12.1968 - 24.11.2014",
    photo: photo40,
    position: "оператор 42-ого окремого мотопіхотного батальйону ",
    texts: [
      "Стасюк Олег Степанович народився 07 грудня 1968 року в місті Вільногірськ Верхньодніпровського району Дніпропетровської області.",
      "У школі активно займався кількома видами спорту: гирьовим, боксом, класичною боротьбою. Мав грамоти, почесні відзнаки не тільки зі спорту, а й за високу дисципліну, глибоке володіння початковою воєнною підготовкою, за  активну громадську діяльність.",
      "1986 – 1988 р.р. – проходив  строкову військову службу в армії. Мав звання «старший сержант».",
      "У 1994 році закінчив Запорізький машинобудівний інститут, факультет автомобіле- і тракторобудування.",
      "У 1997 році із родиною переїхав до селища Балабине на постійне місце проживання.",
      "Працював у Державному підприємстві «Запорізьке машинобудівне конструкторське бюро «Прогрес»  імені академіка О.Г.Івченка», в лабораторії авіадвигунів при інженерному складі.",
      "Неодноразово заохочувався за сумлінну працю керівництвом підприємства, нагороджений медаллю .",
      "Займався самоосвітою та самовдосконаленням. Підтримував Народний Рух України В.Чорновіла, був активістом, справжнім патріотом України, людиною високих моральних принципів.",
      "Був активним учасником Євромайдану.",
      "З серпня 2014 року приймав участь в Антитерористичній операції на території Донецької та Луганської областей з метою виконання службових (бойових) завдань рядовим, стрільцем відділення охорони взводу, охорони роти, охорони в складі 42 батальйону територіальної оборони.",
      "23 листопада 2014 року був тяжко поранений на мосту біля м. Дебальцеве. 24 листопада  помер у лікарні м. Бахмут.",
      "У воїна залишились дружина і дві доньки. ",
      "Похований у смт Балабине Запорізького району Запорізької області.",
      "Указом Президента України № 311/2015 від 4 червня 2015 року, -за особисту мужність і високий професіоналізм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі-, нагороджений орденом «За мужність» III ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 30,
    name: "Олег Олександрович СУЛИМА",
    date: "04.02.1964 - 10.05.2022",
    photo: photo30,
    position: "командир взводу механізованого батальйону",
    texts: [
      "Гірка звістка надійшла у нашу міську територіальну громаду: у боях за Україну загинув командир взводу механізованого батальйону однієї з наших військових частин, старший лейтенант ОЛЕГ ОЛЕКСАНДРОВИЧ СУЛИМА.",
      "Олег Сулима народився 04 лютого 1964 року. Навчався у Вільногірській середній школі №4. Отримав вищу освіту в Харківському танковому училищі за спеціальністю «Інженер з експлуатації броньованої техніки». ",
      "З 1983 по 1990 рік ніс військову службу в якості кадрового офіцера.  ",
      "Виробничу діяльність розпочав у 1990 році слюсарем кооператива «ПАРК».  ",
      "З 2000 по 2008 рік працював слюсарем та печовим з переробки титанов місних та рідкоземельнихматеріалів на Вільногірському державному гірничо-металургійному комбінаті. ",
      "З 2008 року Олег Олександрович працював у сфері надання житлово-комунальнихпослуг. Останнє місце роботи – машиністнасосних установок служби зовнішнього водопроводу та каналізаційних мереж КП «Жилсервіс» Вільногірської міської ради.  ",
      "У трудовому колективі зарекомендував себе з позитивної сторони, користувався повагою колег по роботі. Свої обов’язки виконував кваліфіковано і якісно, адже йому завжди було притаманне високе почуття відповідальності за доручену справу.   ",
      "Загальний стаж роботи нашого земляка становить 35 років. ",
      "24 лютого 2022 року розпочалась активна фаза війни. 02 березня 2022 року Олег Олександрович Сулима був призваний до лав ЗСУ. 10 травня – убитий росіянами у Донецькій області поблизу Мар’їнки під  час артилерійського обстрілу.",
      "Вся наша громада висловлює щирі співчуття дружині загиблого Галині Іванівні, сину Ярославу, всім близьким, рідним, друзям, колегам по роботі. ",
      "Велика вдячність Олегові Сулимі за звитягу і мужність, проявленіу боях, за вірну службу Батьківщині.  ",
      "Світла пам’ять і вічна слава Герою! ",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 38,
    name: "Євгеній Миколайович ХОМЕНКО",
    date: "25.05.1997 - 20.04.2018",
    photo: photo38,
    position: "старший навідник гаубиці",
    texts: [
      "Хоменко Євгеній Миколайович (позивний «Подгон») народився 25 травня 1997 року у м.Вільногірськ Дніпропетровської області. ",
      "Хлопець зростав дуже доброю, вихованою та неконфліктною дитиною. Ще з дитячого садочка почав займатися танцями. ",
      "З 15 років захоплювався боксом, увесь час займався самовдосконаленням та самонавчанням.",
      "Як тисячі інших хлопчаків, він був життєлюбним, активним, енергійним.",
      "2012 року він закінчив 9 класів Вільногірської ЗОШ №5, після чого вступив до металургійного технікуму, у якому здобув спеціальність механіка.",
      "Хлопець зростав в родині справжніх націоналістів і патріотів України.",
      " На початку війни, у 2014 році, його сім'я гостинно прийняла до себе родину вимушених переселенців із Торецька.",
      "Коли Женя познайомився з ними ближче і послухав їхні розповіді про всі ті жахи, що відбувалися на Сході, зрозумів, що не зможе залишитись осторонь подій. Прийняв виважене і самостійне рішення йти на захист батьківщини. ",
      "Для кожної мами благополуччя дитини, здоров’я, гарна освіта - пріоритетна справа.",
      "Женя виконав обіцянку, що дав вдома: вступив на навчання у Національну металургійну академію у Дніпрі, а паралельно готувався до служби в Азов: фізично тренувався, продовжував займатися боксом, щоранку бігав по 10 км, склав іспити на отримання водійських прав. ",
      "За рік, у червні 2016 року, після урочистого вручення диплому, на яке хлопець вдягнув вишиванку, він віддав мамі диплом та сказав: ' Все, мамо, тепер я готовий іти.' ",
      "Наприкінці липня 2016-го, після отримання всіх довідок та дозволів, він поїхав у Київ для проходження курсів молодого бійця на базі Атек, навчався там 4 місяці. ",
      "Цей курс був довший за всі, зазвичай такі курси тривають місяць - півтора. Відбір був приблизно 1 до 5, і Подгон його пройшов.",
      "Після закінчення курсів він приїхав додому на три дні, після чого вперше вирушив на Схід.",
      "Добровольцю Євгенію Хоменкові було на той час 19. Служив у гаубичному артилерійському дивізіоні окремого загону спеціального призначення «Азов».",
      "Був старшим навідником гаубиці. ",
      "Євгеній мав всі ці якості і був вправним воїном.  ",
      "Бойові побратими згадують Женю як хорошого і надійного друга, який ніколи не відмовляв у допомозі. ",
      "Він був душею компанії, доброзичливим, щирим, завжди перебував у доброму гуморі, з посмішкою на вустах.  ",
      "Ще коли він проходив курс молодого бійця, обрав собі позивний Хома з наголосом на 'о'. Він весь курс був із цим позивним. ",
      "Але згодом побратими порекомендували його змінити, оскільки в Азові вже був боєць з таким позивним (Андрій Снітко), і він героїчно загинув 20.08.2014 року в Іловайську. ",
      "Тому хлопець став Подгоном. ",
      "Чому такий досить дивний позивний? ",
      " А тому, що Євгеній любив виконувати спонтанні бажання друзів, близьких.",
      "Наприклад, бували випадки, коли хтось поруч мрійливо казав: -Ех, зараз би коли ковток, то через декілька хвилин хлопець десь знаходив маленьку пляшечку того напою.",
      "Подгон – у сенсі Подарунок.",
      "Даруючи мамі квіти (хоч на свята, хоч просто так), завжди казав: - Це тобі, матусю, подгон від Подгона.",
      "Він був дуже чуйним і галантним.",
      "У нього залишилась наречена, якій Євгеній показав, яким повинен бути справжній чоловік.",
      "Завжди відчиняв перед нею двері, підставляв стілець, підносив шикарні букети, подарунки її мрій. Навіть на відстані вона почувала себе коханою.",
      "Так сталося, що 10 березня смертельне поранення у голову отримав боєць Азова Марк Гудзовський, який прожив ще чотири дні, та 14 березня помер у лікарні імені Мечникова.",
      "Подгон тоді зателефонував до мами і сказав таку страшну фразу: '-Якщо мене поранять у голову, мамо, не треба мене рятувати, відпусти.'  ",
      "Тому, коли батькам зателефонували, мама вже все відчувала.",
      "Жив хлопець, кохав, мріяв стати після війни слідчим. Але доля виявилася жорстокою. ",
      "18 квітня близько 6:00 ранку під час виконання бойового завдання у Павлополі Донецької області Євгеній отримав численні поранення тіла і важкі осколкові травми голови.  ",
      "На жаль, всі зусилля лікарів Маріуполя врятувати Женю виявилися марними, вранці 20 квітня він помер. ",
      "Після прощання із побратимами на базі 'Азова' у місті Урзуф Євгенія привезли у рідне місто. ",
      "Поховали Героя на Алеї слави міського кладовища 22 квітня 2018 року.",
      "У нього залишились батьки та брат, який тоді, у свої 13 років, виявив рішуче бажання піти по стопах брата і виконав його.",
      "2018 року громада міста визнала Євгенія Людиною Року у номінації «Гордість міста» (посмертно). Нагрудний знак і посвідчення вручили мамі Героя.",
      "На фасаді технікуму, де навчався Євгеній, у червні 2018 року встановлено меморіальну дошку на його честь.",
      "Пам’ять про Євгенія Хоменка навіки залишиться в серцях всіх, хто його знав і любив.Щирі співчуття рідним і близьким.",
      "03 березня 2021 року Євгеній удостоєний відомчої заохочувальної відзнаки Національної Гвардії України - нагрудного знаку `ЗА ДОБЛЕСНУ СЛУЖБУ`.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
      "19 квітня 2024 року за проявлені мужність та відвагу в обороні держави Євгенія нагороджено почесною нагородою – медаллю «Учасник територіальної оборони». Медаль вручено матері воїна на урочистому заході місцевого коледжу, випускником якого він був.",
      "Світла і вічна пам'ять Герою! ",
    ],
    sliderImg: [Hom1, Hom2, Hom3, Hom4, Hom5, Hom6, Hom8, Hom9, Hom10],
  },

  {
    id: 31,
    name: "Віталій Ігорович ЦОЙ",
    date: "22.09.1979 - 12.04.2022",
    photo: photo31,
    position: "сержант",
    texts: [
      "12 квітня2022 року у боях за Україну загинув вільногірець Віталій  Ігорович  Цой. ",
      "Народився Віталій 22 вересня 1979 року у місті Вільногірськ. Навчався у середній загальноосвітній школі №1. ",
      "З 1997 по 1999 рік виконував військовий обов’язок на легендарному фрегаті «Гетьман Сагайдачний». Мав звання головного старшини Військово-морських сил України. ",
      "Після демобілізації працював токарем, був гарним знавцем своєї справи. Останнє місце роботи – ТОВ «ПП «Гидроспецсервіс». ",
      "Після оголошення загальної мобілізації Віталій Ігорович Цой вже 2 березня відправився у військову частину для проходження служби. Служив у 17-й окремій танковій криворізькій бригаді, а потім – у 25-й механізованій бригаді. ",
      "Загинув воїн 12 квітня в результаті ворожого артобстрілу під час виконання бойового завдання біля міста Попасна Луганської області. ",
      "Поховали Віталія на Алеї Слави міського цвинтаря. ",
      "Для побратимів, рідних та друзів, для всієї нашої громади Віталій Ігорович Цой назавжди залишиться мужнім, відважним воїном, який поклав власне життя на вівтар перемоги нашої держави у війні з російським агресором.",
      "Щирі співчуття матері загиблого Ганні Петрівні, всім його близьким та колегам по роботі!",
      "Указом Президента України № 347/2022 Про відзначення державними нагородами України «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Віталія Цоя нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [coy1, coy2, coy3],
  },
  {
    id: 39,
    name: "Максим Андрійович ЧАЙКА",
    date: "26.11.1984 - 28.09.2023",
    photo: photo39,
    position: "навідник кулеметного взводу",
    texts: [
      "«Татко, повертайся скоріше!», - просила донька воїна. І він сам так хотів додому, до рідних. Як і всі наші захисники – ще зовсім недавно звичайні мирні люди, а зараз військові.  ",
      "Не судилося… Ворог по-іншому розпорядився долею нашого земляка. ",
      "Страшна звістка прийшла у родину від побратимів, офіційне сповіщення в громаду - від військового керівництва: 28 вересня 2023 року, виявивши стійкість і мужність під час виконання бойових завдань у складі штурмової роти штурмового батальйону однієї з військових частин ЗСУ, в районі населеного пункту Андріївка Бахмутського району Донецької області загинув солдат, навідник кулеметного взводу, вільногірець Максим Андрійович ЧАЙКА.",
      "Народився Максим 26 листопада 1984 року в місті Вільногірськ. Закінчив загальноосвітню школу №3. Вивчився на електрика в місцевому технікумі. За фахом і працював у різні роки на ТОВ «Вільногірське скло», ТОВ «Цвєтмет», філії «ВГМК» АТ «ОГХК». Останнє місце роботи – електромонтер у ТОВ «Мотронівський гірничозбагачувальний комбінат».  ",
      "Завжди був гарним спеціалістом, добропорядною людиною, мав багато друзів. Дуже цінував родину, в якій зростало дві донечки. ",
      "Але росія зруйнувала всі плани, все життя Максима, його родини, всієї нашої країни, прийшовши вогнем і смертю на нашу землю. ",
      "01.03.2023 року він призваний Кам’янським РТЦК та СП на військову службу по мобілізації. Після проходження місячної військової підготовки Максим вирушив на Харків, потім були Дружківка, Костянтинівка… Дуже спритний, швидкий, розторопний, він не раз рятував життя товаришам на фронті. Взяв на передову власне авто, і коли щастило отримати 2-3 доби передиху, їхав додому і обов’язково підвозив і своїх побратимів, навіть в інші області.",
      "Остання телефонна розмова з родиною відбулась у середу, 27 вересня. Вдома як раз готувалися до благодійного ярмарку. Молодша Маша декілька разів молила батька якнайскоріше повернутися додому, бо дуже скучила. Обіцяв дружині: «Танюшко, я обов’язково повернуся, чекайте. Виживу любою ціною!». ",
      "А потім – затяжна тиша, відсутність зв’язку і страшна звістка від побратима про загибель Максима. ",
      "Не дочекалися його повернення батьки, дружина, доньки. Всією громадою висловлюємо щирі співчуття родині, а також друзям, колегам, побратимам нашого земляка.",
      "Ціна нашої свободи, ціна миру і спокою, майбутнього України – життя таких наших співвітчизників, як Максим.",
      "Низький уклін їм, наша щира вдячність і пам’ять навіки.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },

  {
    id: 32,
    name: "Юрій Васильович ЧЕРНЕНКО",
    date: "23.04.1979 - 19.04.2022",
    photo: photo32,
    position: "гранатометник механізованого батальйону",
    texts: [
      "Вільногірська міська територіальна громада попрощалася ще з одним захисником України – молодшим сержантом Юрієм Васильовичем Черненком, 23.04.1979 року народження. Уродженець с. Кринички Криничанського району Дніпропетровської області, Юрій проживав у с. Дідове Дмитрівського старостинського округу Вільногірської міської ради Кам’янського району, був учасником АТО.  ",
      "На військову службу по мобілізації призваний з першого дня активних військових дій - 24.02.2022 року відділом Кам’янського РТЦК та СП Дніпропетровської області, служив гранатометником механізованого батальйону однієї з військових частин ЗСУ.  ",
      "Виявивши стійкість і мужність при захисті України, Юрій Черненко загинув 19 квітня 2022 року. Похоронений 25 квітня, на цвинтарі у селі Дідове. Без батька залишилось 6 дітей. Співчуття родині та друзям загиблого. ",
      "Указом Президента України від 11 травня 2022 року № 329/2022 Про відзначення державними нагородами України «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Юрія Черненка нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [chernenko1, chernenko3, chernenko4],
  },
  {
    id: 33,
    name: "Максим Олександрович ШЕВЧЕНКО",
    date: "02.10.1982 - 06.11.2022",
    photo: photo33,
    position: "стрілець у ІІ-му стрілецькому батальйоні ",
    texts: [
      "У вогні війни Україна втрачає кращих своїх синів. Наша громада знову в скорботі – загинув житель Вільногірська, наш захисник і Герой Максим Олександрович ШЕВЧЕНКО.  ",
      "Максим Олександрович народився 1982 року в с.Лихівка П'ятихатського району. 2000 року закінчив Лихівську загальноосвітню школу, навчався у Дніпропетровському технікумі електрифікації на електроенергетика. Повну вищу освіту отримав у Дніпродзержинському державному технічному університеті,здобув кваліфікацію інженера-електромеханіка.  ",
      "З 2003 року працював на філії «Вільногірський ГМК'» АТ «ОГХК». У колектив і завжди відзначався чемністю, доброзичливістю. Мав добрі товариські стосунки з колегами, вірних друзів. ",
      "Коли у лютому 2022 року розпочалась широкомасштабна російська агресія, він не міг залишатися байдужим до долі країни. Відразу вступив до лав територіальної оборони, а 18 березня Центрально-Чечелівським РТЦК та СП м. Дніпро був призваний по мобілізації до Національної гвардії України.  Солдат Максим Шевченко служив стрільцем у ІІ-му стрілецькому батальйоні однієї з військових частин.  ",
      "Загинув наш земляк 06 листопада під час виконання бойового завдання в районі населеного пункту Майорськ Донецької області. ",
      "Воїн світла, воїн добра… Ці слова про таких, як Максим ШЕВЧЕНКО, про тисячі наших захисників, які зі зброєю в руках пішли відвойовувати щастя, мир і свободу для своїх батьків, дружин,дітей. ",
      "Гідний син нашої держави, справжній борець за її територіальну цілісність і незалежність, Максим ШЕВЧЕНКО назавжди залишиться для нас Героєм-визволителем.",
      "Вся громада висловлює щирі співчуття дружині Тетяні, дітям, батькам, всімрідним, близьким, колегам по роботі, фронтовим побратимам нашого захисника.",
      "Указом Президента України № 81/2023 Про відзначення державними нагородами України «за особисту мужність і самовідданість, виявлені у захисті державного суверенітету та територіальної цілісності України, сумлінне і бездоганне служіння Українському народові» Максима Шевченка нагороджено орденом «За мужність» ІІІ ступеня (посмертно).",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 48,
    name: "Віталій Віталійович ШЕВЧУК",
    date: "14.01.1980 - 13.11.2023",
    photo: photo48,
    position: "начальник електростанції",
    texts: [
      "Сумна звістка надійшла у Вільногірську громаду: 13 листопада 2023 року у засвіти пішов наш земляк, захисник України Віталій Віталійович ШЕВЧУК.  ",
      "Віталій народився 14 січня 1980 року в селі Полівське Верхньодніпровської району Дніпропетровської області. ",
      "Закінчив Вільногірську середню школу №2, Вільногірський металургійний технікум. ",
      "Вищу освіту по спеціальності 'Інженер-механік' здобув у Дніпродзержинському (зараз: Дніпровському) державному технічному університеті.   ",
      "Віталій Віталійович працював у ТОВ 'Вільногірське скло', останні 15 років - помічником машиніста екскаватора і механіком на філії 'Вільногірський гірничо-металургійний комбінат' АТ 'ОГХК'",
      "Всі, хто знав Віталія, назавжди запам'ятають його як добродушну світлу людину, хорошого товариша, завжди готового прийти на допомогу, гарного люблячого сім'янина.",
      "Коли розпочалась широкомасштабна російська агресія, не вагаючись, записався у лави територіальної оборони.",
      "З 18 березня 2022 року добровольцем пішов боронити рідну державу у складі військової частини 3036 Національної гвардії України. Служив стрільцем-радіотелефоністом у батальйоні 'Дніпро-1'.",
      "Після тяжкого поранення у квітні 2022 року і лікування у госпіталі продовжував нести службу на посаді начальника електростанції у Підгородному на Дніпропетровщині.",
      "Внаслідок різкого погіршення самопочуття Віталія ШЕВЧУКА направили до лікарні, де виявили гострий лейкоз. На жаль, у медичному закладі наш земляк і помер. Лікарі не взмозі були його врятувати.",
      "Вся громада висловлює щирі співчуття родині Віталія: мамі, дружині, двом донькам, братові, онучці, а також всім його друзям, колегам по роботі, бойовим побратимам. Низький уклін матері Героя за виховання справжнього громадянина України, вірного сина свого народу, гарної Людини.",
      "Гіркою і жахливо високою ціною дається наша боротьба проти загарбників, наша омріяна довгоочікувана перемога. Не згасне пам'ять народу про ратний подвиг наших захисників і захисниць. Імена таких воїнів, як Віталій ШЕВЧУК, навічно вписані в історію України.",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 34,
    name: "Олександр Володимирович ШЕМЕТ",
    date: "13.10.1969 - 30.01.2023",
    photo: photo34,
    position: "стрілець-санітар мотопіхотного відділення",
    texts: [
      "Народився Олександр 13 жовтня 1969 року у місті Вільногірськ. Навчався в середній школі №5, потім здобував спеціальність машиніста бульдозера в нашому професійно-технічному училищі.  ",
      "Строкову армійську службу проходив у місті Байконур в Казахстані. Коли повернувся додому, працював на Вільногірському ГМК, потім – у м. Кам’янське. ",
      "07 вересня 2022 року Першим відділом Кам’янського РТЦК та СП Дніпропетровської області Олександра було призвано на військову службу по мобілізації. Як стрілець-санітар мотопіхотного відділення однієї з військових частин він брав участь у боях на Сіверському напрямку.  ",
      "30 січня 2023 року сержант ЗСУ Олександр Володимирович ШЕМЕТ помер під час виконання службових обов’язків у зоні бойових дій.  ",
      "Від всієї громади висловлюємо глибокі співчуття мамі полеглого Світлані Борисівні, двом дітям Олександра, сестрі, братам, всім його друзям та колегам ",
      "Дякуємо воїнові за мужність і звитягу, проявлені у справі захисту територіальної цілісності та суверенітету нашої держави. Вічна йому пам’ять! ",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
  },
  {
    id: 35,
    name: "Дмитро Олегович ЮХИМЕЦЬ",
    date: "10.10.1998 - 20.06.2022",
    photo: photo35,
    position: "На фронті був навідником однієї із штурмових частин",
    texts: [
      "Вільногірська громада знову у скорботі. Підступний ворог забирає життя кращих синів Українського народу, цвіт нації. 20 червня 2022 року у боях за Донецьку область загинув наш земляк ДМИТРО ОЛЕГОВИЧ ЮХИМЕЦЬ. ",
      "Народився Дмитро Юхимець 10 жовтня 1998 року у м. Вільногірськ, навчався у загальноосвітній школі №4. ",
      "Після школи здобув професію електромонтера  з ремонту та обслуговування електроустаткування у Західно-Дніпровському центрі професійно-технічної освіти. Виробничу практику проходив у ТОВ «Склянний Альянс».  ",
      "Характеризувався педагогами і наставниками як працелюбний, позитивний, активний юнак. Займався спортом, захоплювався комп’ютерами, був завзятим автолюбителем. ",
      "Як добропорядний і гідний громадянин своє держави, у 2019 році Дмитро пройшов строкову службу у лавах ЗСУ (військово-морський флот). ",
      "Під час розгортання активної фази війни 03 червня 2022 року відділом Кам’янського РТЦК та СП Дніпропетровської області його призвано на військову службу. На фронті був навідником однієї із штурмових частин. ",
      "Вірний військовій присязі, 20 червня Дмитро героїчно загинув на полі бою, захищаючи наші життя, цілісність і незалежність України.",
      "У Героя залишились мати, батько і два брати. Щирі співчуття їм, всім близьким і друзям загиблого воїна. ",
      "Низький уклін Дмитрові за мужність і звитягу.",
      "Указом Президента України №745/2022 Про відзначення державними нагородами України «за особисту мужність і самовіддані дії, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі» Дмитра Юхимця нагороджено орденом «За мужність» ІІІ ступеня (посмертно)",
      "За особисту мужність і героїзм, виявлені у захисті державного суверенітету та територіальної цілісності України, вірність військовій присязі та незламність духу рішенням Вільногірської міської ради Дніпропетровської області № 1310-54/VIII від 26.10.2023 воїну присвоєно звання «Почесний громадянин Вільногірської міської територіальної громади» (посмертно).",
    ],
    sliderImg: [uhim1, uhim2, uhim3, uhim4, uhim5, uhim6],
  },
];
